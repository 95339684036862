import { useState, useRef, useEffect } from 'react';

import ForwardDialog from '../../components/modal/forward';
import Portal from '../../components/context/portal';
import { numberFormatCurrency, parseJSON } from '../../components/util/index';
import api from '../../components/service/api.ts';

import styled from 'styled-components';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size:0.9em;

  td, th {
    border: 1px solid #dddddd;
    padding: 6px 8px;
    width:20%;
  }

  td:first-child , th:first-child {
    width:25%;
  }

  td:nth-child(3) , th:nth-child(3) {
    width:15%;
  }

  td:last-child , th:last-child {
    width:50%;
  }

  th {
    font-weight:500;
    text-transform: uppercase;
    font-size:.85em;
    background-color:#F9FAFB;
  }
  
  // tr:nth-child(even) {
  //   background-color: #dddddd;
  // }
`;

const InputStripe = styled.input`
  background: repeating-linear-gradient(
    45deg,
    rgba(0,0,0, 0.05),
    rgba(0,0,0, 0.05) 10px,
    white 10px,
    white 20px
  );

  &:focus {
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
`;

const getDiscount = async (quantity) => {
  const { symbol, jm, convert } = quantity;
  const resp = await api.getTraderDiscount(symbol, jm, convert);
  return resp?.data;
};

const CalcProduct = ({ title = "", quantity = {}, handleClose = () => {}, handleUpdate = (id, price = 0) => {} }) => {
  const [dataPrice, setDataPrice] = useState(null);
  const [blockOK, setBlockOk] = useState(false);
  const refInput1 = useRef();
  const refInput2 = useRef();

  useEffect(() => {
    setDataPrice(null);

    getDiscount(quantity).then((data) => {
      if (data?.status !== 0) {
        handleClose();
        return;
      }

      const dataPriceJson = parseJSON(data?.dane);
      if (!dataPriceJson) return;

      setDataPrice(dataPriceJson);
      setTimeout(() => {
        refInput1.current.setSelectionRange(0, refInput1.current.value.length);
        refInput1.current.focus();
      }, 10);
    });
  }, []);

  const closeModal = () => {
    // const container = document.getElementById('show-modal');
    // unmountComponentAtNode(container);
    setTimeout(() => handleClose());
  };

  const goModal = () => {
    const priceCurrent = parseFloat(refInput2.current.value.replace(",", ".").replaceAll(" ", "").replace(/[^\d.-]/g, "").trim()).toFixed(2);
    if (priceCurrent <= 0) return;

    handleUpdate(quantity.id, priceCurrent);
    setDataPrice(null);
    // closeModal();
  };

  const validPrice = () => {
    if (!refInput2.current) {
      setBlockOk(true);
      return;
    }

    const price = parseFloat(String(refInput2.current.value).replaceAll(" ", "").replace(",", ".").replace(/[^\d.]/g, "").trim() || 0);

    if (price <= 0) {
      setBlockOk(true);
      return;
    }

    setBlockOk(false);
  };

  const calcPrice = (e, type) => {
    // const priceNormal = dataPrice?.base_price || 0; // base_price, purchase_price, price
    const priceNormal = dataPrice?.price * (/* quantity?.convert || */ 1) || 0; // base_price, purchase_price, price

    if (type == 0) {
      let rabat = parseFloat(String(refInput1.current.value).replace(",", ".").replace("--", "-").replace(/[^\d.-]/g, "").trim() || 0).toFixed(2);

      if (Number.isNaN(rabat) || rabat == "NaN") rabat = 0;

      const negative = String(refInput1.current.value)[0] == "-" ? "-" : "";

      rabat = String(refInput1.current.value) === "-" ? parseFloat("-0") : rabat;
      rabat = rabat > 1000 ? 1000 : rabat < -1000 ? -1000 : rabat;

      const price = rabat > 100 ? 0 : Math.abs(priceNormal - (priceNormal * rabat * 0.01));
      refInput2.current.value = numberFormatCurrency(price).replace("zł", "").trim();

      // const selectionStart = String(refInput1.current.value) === "-" ? refInput1.current.selectionStart + 1 : refInput1.current.selectionStart;
      const selectionStart = refInput1.current.selectionStart;
      refInput1.current.value = negative + parseFloat(Math.abs(rabat)).toFixed(2);
      refInput1.current.setSelectionRange(selectionStart, selectionStart);

      validPrice();
      return;
    }

    let priceP = parseFloat(String(refInput2.current.value).replaceAll(" ", "").replace(",", ".").replace(/[^\d.]/g, "").trim() || 0);

    if (Number.isNaN(priceP) || priceP == "NaN") priceP = priceNormal;
    // const price = priceP > (priceNormal * 2) ? (priceNormal * 2) : priceP < 0 ? 0 : priceP;
    // const rabat = 100 - (price / priceNormal) * 100;

    const price = priceP > (priceNormal * 10) ? (priceNormal * 10) : priceP < 0 ? 0 : priceP;

    let rabat = 0;
    if (price > priceNormal)
      rabat = - (price / (priceNormal * 10)) * 1000;
    else
      rabat = 100 - (price / priceNormal) * 100;
    
    const negative = rabat < 0 ? "-" : "";
    refInput1.current.value = negative + parseFloat(Math.abs(rabat)).toFixed(2);

    const selectionStart = refInput2.current.selectionStart;
    refInput2.current.value = numberFormatCurrency(Math.abs(price)).replace("zł", "").trim();
    refInput2.current.setSelectionRange(selectionStart, selectionStart);

    validPrice();
  };

  if (dataPrice == null) {
    return '';
  }

  return (
    <>
      <Portal elementId="show-modal">
        <ForwardDialog
          title={`Indywidualna cena produktu`}
          open
          onClose={closeModal}
          onGoTo={goModal}
          textGoTo="Potwierdź cenę"
          blockOK={blockOK}
        >
          <>
            <div className='w-full mb-4'>
              <div>
                Produkt: <b>{title}</b>
              </div>
              {dataPrice?.purchase_price != undefined && (
                <div>
                  Ostatnia cena zakupu: <span className='text-red-400'>{numberFormatCurrency(dataPrice.purchase_price)}</span>
                </div>
              )}
            </div>
            {/* <hr className='my-2 border border-dashed border-t-1' /> */}
            {dataPrice?.selling_prices != undefined && dataPrice?.selling_prices.length > 0 && (
              <div className='flex flex-col mb-2'>
                <p className='mb-2 font-semibold'>Historia sprzedaży:</p>
                <Table>
                  <thead>
                    <tr>
                      <th className='text-left'>Data</th>
                      <th className='text-right'>Ilość / JM</th>
                      {/* <th className='text-right'>VAT</th> */}
                      <th className='text-right'>Cena netto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPrice.selling_prices.map((val, index) => (
                      <tr key={`selling_prices_${index}`}>
                        <td>
                          {val.data.slice(0, 10).replace(/\./g, '-')}
                        </td>
                        <td className='text-right'>
                          {val?.ilosc || '~'} {val.symbol_jm}
                        </td>
                        {/* <td className='text-right'>
                          {val.stawka_vat}%
                        </td> */}
                        <td className='text-right'>
                          {numberFormatCurrency(val.cena).replace("zł", "").trim()} {val.symbol_wal || "zł"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            {dataPrice?.purchase_prices != undefined && dataPrice?.purchase_prices.length > 0 && (
              <div className='flex flex-col mb-2'>
                <p className='mb-2 font-semibold'>Historia cen zakupu:</p>
                <Table>
                  <thead>
                    <tr>
                      <th className='text-left'>Data</th>
                      <th className='text-right'>Ilość / JM</th>
                      {/* <th className='text-right'>VAT</th> */}
                      <th className='text-right'>Cena netto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPrice.purchase_prices.map((val, index) => (
                      <tr key={`purchase_prices_${index}`}>
                        <td>
                          {val.data.slice(0,10).replace(/\./g, "-")}
                        </td>
                        <td className='text-right'>
                          {val?.ilosc || '~'} {val.symbol_jm}
                        </td>
                        {/* <td className='text-right'>
                          {val.stawka_vat}%
                        </td> */}
                        <td className='text-right'>
                          {numberFormatCurrency(val.cena).replace("zł", "").trim()} {val.symbol_wal || "zł"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            <div className='flex flex-col gap-1'>
              <div className='flex items-center gap-x-2'>
                <span className='flex-grow text-right'>Cena nominalna:</span>
                <div className='relative flex items-center gap-2'>
                  <InputStripe
                    className='w-40 text-sm text-right text-gray-600 border border-gray-200 rounded outline-none cursor-not-allowed pr-11 ring-0 focus:ring-0' 
                    type="text"
                    readOnly={true}
                    defaultValue={numberFormatCurrency(dataPrice?.base_price * (/* quantity?.convert || */ 1)).replace("zł", "").trim()}
                  />
                  <div className='absolute right-0 px-3 mr-px text-sm leading-9 text-center text-gray-600 align-middle bg-gray-100 rounded rounded-l-none h-9'>
                    {dataPrice?.currency.translateCurrency()}
                  </div>
                </div>
              </div>
              <hr className='my-2 border border-dashed border-t-1' />
              <div className='flex items-center gap-x-2'>
                <span className='flex-grow text-right'>Rabat:</span>
                <div className='relative flex items-center justify-center gap-2'>
                  <input
                    ref={refInput1}
                    className='w-40 text-sm text-right text-gray-600 bg-white border border-gray-300 rounded outline-none pr-11 focus:outline-none focus:shadow-none ring-opacity-0 ring-0 focus:ring-1' 
                    type="text"
                    defaultValue={0}
                    onChange={(e) => calcPrice(e, 0)}
                    onFocus={(e) => e.currentTarget.select()}
                    tabIndex={1}
                  />
                  <div className='absolute right-0 px-3 mr-px text-sm leading-9 text-center align-middle bg-gray-100 rounded rounded-l-none h-9'>%</div>
                </div>
              </div>
              <div className='flex items-center gap-x-2'>
                <span className='flex-grow text-right'>Cena po rabacie:</span>
                <div className='relative flex items-center justify-center gap-2'>
                  <input
                    ref={refInput2}
                    className='w-40 text-sm text-right text-gray-600 bg-white border border-gray-300 rounded outline-none pr-11 focus:outline-none focus:shadow-none ring-opacity-0 ring-0 focus:ring-1' 
                    type="text"
                    defaultValue={numberFormatCurrency(dataPrice.price * (/* quantity?.convert || */ 1)).replace("zł", "").trim()}
                    onChange={(e) => calcPrice(e, 1)}
                    onFocus={(e) => e.currentTarget.select()}
                    tabIndex={2}
                  />
                  <div className='absolute right-0 px-3 mr-px text-sm leading-9 text-center align-middle bg-gray-100 rounded rounded-l-none h-9'>
                    {dataPrice?.currency.translateCurrency()}
                  </div>
                </div>
              </div>
            </div>
          </>
        </ForwardDialog>
      </Portal>
    </>
  );
}

export default CalcProduct;