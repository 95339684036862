import { useEffect, useReducer, useRef, useState } from 'react'
import axios, { AxiosRequestConfig, Canceler } from 'axios'
import http from '../service/http';

// State & hook output
interface State<T> {
  status: 'init' | 'fetching' | 'error' | 'fetched'
  data?: T
  error?: string
  cancel: Canceler
  fetch: (data: object) => void
}

interface Cache<T> {
  [watcher: string]: T
}

// discriminated union type
type Action<T> =
  | { type: 'request' }
  | { type: 'success'; payload: T }
  | { type: 'failure'; payload: string }

function useFetch<T = unknown>(
  url?: string,
  method?: "post" | "get",
  data?: object,
  options?: AxiosRequestConfig,
): State<T> {
  const cache = useRef<Cache<T>>({})
  const cancelRequest = useRef<boolean>(false)
  
  // cancel func
  let cancel:Canceler = (message?:string) => {};
  let cancelToken = new axios.CancelToken(c => cancel = c);

  const initialState: State<T> = {
    status: 'init',
    error: undefined,
    data: undefined,
    cancel,
    fetch: (data) => setWatcher(url + "_" + JSON.stringify(data)),
  }

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'request':
        return { ...initialState, status: 'fetching' };
      case 'success':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'failure':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState);
  const [watcher, setWatcher] = useState(url + "_" + JSON.stringify(data));

  // console.log("userFetch watcher", watcher);

  useEffect(() => {
    // console.log("useFetch", url);

    if (!url) return;

    const fetchData = async () => {
      cancelRequest.current = false;
      // console.log("useFetch", "request", typeof cache.current[watcher]);

      dispatch({ type: 'request' });

      if (typeof cache.current[watcher] === "object") {
        // console.log("useFetch", "CACHE", cache.current[watcher]);
        dispatch({ type: 'success', payload: cache.current[watcher] });
      } else {
        try {
          // console.log("useFetch", "POST")
          const response = await http.post(url, data, {...options, cancelToken});
          cache.current[watcher] = response.data;

          if (cancelRequest.current) return

          dispatch({ type: 'success', payload: response.data });
        } catch (error: any) {
          if (cancelRequest.current) return;

          dispatch({ type: 'failure', payload: error.message });
        }
      }
    }

    fetchData();

    return () => {
      // console.log("useFetch UNHOOK");
      cancelRequest.current = true;
      cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watcher]);

  return state;
}

export default useFetch;
