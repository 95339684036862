import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';

const SelectCalendar = ({
  name,
  defaultValue = "",
  className = "",
  onChange = (value) => {}, 
  defaultMessage = '-----'
}) => {
  const [selected, setSelected] = useState(defaultValue.toDateInput(true)); // key
  const [isOpen, setIsOpen] = useState(false);
  const refTarget = useRef(null);

  const clearInput = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelected('');
    setIsOpen(false);

    typeof onChange == "function" && onChange(selected);
  };

  const onBlurCalendar = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) 
      return;

    isOpen == true && setIsOpen(false);
  };

  const onShowCalendar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(!isOpen);
    if (isOpen == false) {
      setTimeout(() => refTarget.current.focus(), 0);
    }
  };

  const onChangeCalendar = (date) => {
    setIsOpen(false);
    setSelected(date.toDateInput());
    setTimeout(() => {
      typeof onChange == "function" && onChange(selected);
    }, 0);
  };

  useEffect(() => {
    setSelected(defaultValue.toDateInput(true));
  }, [defaultValue]);

  return (
    <div
      className={`relative max-w-sm`}
      onBlur={onBlurCalendar}
      ref={refTarget}
      tabIndex="1"
    >
      <input type="hidden" name={name} value={selected} />
      <button
        type="button"
        className={`relative w-full py-2 pr-14 text-left bg-white border rounded shadow-sm cursor-default  
                  sm:text-sm ring-1 ${className} ${isOpen ? 'border-primary ring-primary' : 'border-gray-300 ring-transparent'}`}
        onClick={onShowCalendar}
        readOnly
      >
        <span className='relative z-0 items-start block w-full pl-3'>
          {selected == "" ? <i className='text-gray-500'>{defaultMessage}</i> : selected.toDateInput()}&nbsp;
        </span>
        <a
          href={void(0)}
          className={`${(selected == "") ? 'hidden' : ''} absolute right-8 top-1.5 z-10 hover:cursor-pointer`}
          type="button"
          onClickCapture={clearInput}
        >
          {/* ICON CROSS */}
          <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
            <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
          </svg>
        </a>
        {/* ICON CALENDAR */}
        <svg className={`absolute w-5 h-5 right-2 top-2 text-gray-500 group-focus:text-blue-500`} viewBox="0 0 36.447 36.447" fill="currentColor">
          <g>
            <path d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75 c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75 c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v24c0,2.343,1.907,4.25,4.25,4.25h24 c2.344,0,4.25-1.907,4.25-4.25v-24C34.474,5.855,32.567,3.948,30.224,3.948z M25.286,2.75c0-0.689,0.525-1.25,1.17-1.25 c0.646,0,1.17,0.561,1.17,1.25v4.896c0,0.689-0.524,1.25-1.17,1.25c-0.645,0-1.17-0.561-1.17-1.25V2.75z M17.206,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M9.125,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75v-22h27.5V32.198z" />
            <rect x="6.724" y="14.626" width="4.595" height="4.089" />
            <rect x="12.857" y="14.626" width="4.596" height="4.089" />
            <rect x="18.995" y="14.626" width="4.595" height="4.089" />
            <rect x="25.128" y="14.626" width="4.596" height="4.089" />
            <rect x="6.724" y="20.084" width="4.595" height="4.086" />
            <rect x="12.857" y="20.084" width="4.596" height="4.086" />
            <rect x="18.995" y="20.084" width="4.595" height="4.086" />
            <rect x="25.128" y="20.084" width="4.596" height="4.086" />
            <rect x="6.724" y="25.54" width="4.595" height="4.086" />
            <rect x="12.857" y="25.54" width="4.596" height="4.086" />
            <rect x="18.995" y="25.54" width="4.595" height="4.086" />
            <rect x="25.128" y="25.54" width="4.596" height="4.086" />
          </g>
        </svg>
      </button>
      {isOpen && (
        <div className="absolute left-0 z-10 mt-2 bg-white rounded xs:right-0 xs:left-auto shadow-cart">
          <span className="react-arrow right-10 -top-1" />
          <div className="relative z-10 p-2 bg-white rounded ">
            <Calendar
              locale="pl-PL"
              minDate={new Date()}
              onChange={onChangeCalendar}
              defaultValue={selected.toDateCalendar() || (new Date())}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectCalendar;
