import { useContext } from 'react';
import { GlobalStateContext } from '../context/global';

const ExtraInfo = () => {
  const globalState = useContext(GlobalStateContext);
  if (!!globalState.user?.extra_info != true) return '';
  if (globalState.user?.extra_info.replace(/<[^>]+>/g, '') == '') return '';

  return (
    <header className="extra-info">
      <div className="h-6 leading-5 py-0.5 bg-orange-400 text-white text-center font-medium text-sm">
        {globalState.user?.extra_info.replace(/<[^>]+>/g, '') || "..."}
      </div>
    </header>
  );
}

export default ExtraInfo;
