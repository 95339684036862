import Dialog from './dialog';
import Button from './button';
import Translate from '../translate/Translate';

// interface Props {
//   title: string;
//   children: React.ReactNode;
//   open: boolean;
//   onClose: Function;
//   onConfirm: Function; 
// }

export default function Confirm(props) {
  const { open, onClose, title, children, onConfirm } = props;

  if (!open) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="p-4 pb-6">
        <h2 className="text-xl"><Translate ns="dialog" value={title} /></h2>
        <div className="pt-5">{children}</div>
      </div>
      <div className="flex justify-end p-3 bg-gray-100 rounded-b-lg">
        <div className="p-1">
          <Button
            onClick={() => onClose()}
            className="font-normal text-white bg-red-400 hover:bg-gray-800"
          >
            <Translate ns="dialog" value="Nie" />
          </Button>
        </div>
        <div className="p-1">
          <Button
            onClick={() => {
              onClose();
              onConfirm();
            }}
            className="text-white bg-primary"
          >
            <Translate ns="dialog" value="Tak" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}