import { useState, useRef, useEffect } from 'react';

const getSelectDefault = (list, defaultValue) => list.find(el => el.value == defaultValue);

const SelectNormal = ({ 
  name,
  defaultValue = "", 
  list = ([{ value: "", key: "", label:"" }]), 
  onChange = (value) => {}, 
  className='', 
}) => {
  const [selected, setSelected] = useState(getSelectDefault(list, defaultValue)); // key
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const refList = useRef();

  const onShowList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(!isOpen);
    showInput === true && setShowInput(false);

    setTimeout(() => {
      refList.current.focus();
    }, 100);
  };

  const onHideList = () => {
    setIsOpen(false);
    showInput === true && setShowInput(false);
  };

  const onSelect = (_, el) => {
    // e.preventDefault();
    setSelected(el);
    setTimeout(() => onChange(el.value), 10);

    return;
  };

  useEffect(() => {
    setSelected(getSelectDefault(list, defaultValue));
  }, [defaultValue]);

  return (
    <>
      <div className={`relative max-w-sm`}>
        <input type="hidden" name={name} value={selected?.value || ""} />
        <button
          type="button"
          // aria-haspopup="listbox"
          // aria-labelledby="listbox-label"
          className={`relative w-full py-2 pr-9 text-left bg-white border rounded shadow-sm cursor-default  
            sm:text-sm ring-1 ${className} ${isOpen ? 'border-primary ring-primary' : 'border-gray-300 ring-transparent'}`}
          onMouseDown={onShowList}
        >
          <span 
            className={`relative z-0 items-start block w-full pl-3 ${selected == undefined ? 'text-gray-500 italic' : ""}`}
            dangerouslySetInnerHTML={{ __html: selected?.label || `------`}}
          />
          <p className={`absolute inset-y-0 right-0 flex items-center pl-2 pr-3 z-10`}>
            {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" clipRule="evenodd" d="M10 3a1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
            </svg> */}
            <svg className="w-3 h-3 text-gray-500" viewBox="-5 -6 38 38" fill="currentColor">
              <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"/>
            </svg>
          </p>
        </button>
        <div
          tabIndex={-1}
          ref={refList}
          onBlur={onHideList}
          className={`absolute w-full z-20 transition-opacity mt-1 right-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
        >
          <ul
            role="listbox"
            // aria-labelledby="state-cart-label"
            // aria-activedescendant="state-cart-0"
            className="w-full py-1 overflow-auto text-sm rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {list.map(el => (
              <li
                key={`prop_selectsingle_choose_${el.key}_${el.value}`}
                role="option"
                className={`relative cursor-default select-none hover:bg-gray-200 ${selected?.value == el.value ? 'bg-primary text-white hover:text-primary' : 'text-gray-700 '}`}
              >
                <button
                  type="button"
                  onMouseDown={(e) => onSelect(e, el)}
                  className="w-full px-3 py-2 outline-none"
                >
                  <div className="flex items-start">
                    <span 
                      className="block text-sm font-normal text-left truncate"
                      dangerouslySetInnerHTML={{ __html: el.label }}
                    />
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SelectNormal;
