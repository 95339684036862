import MenuSide from '../../components/side/menuside';
import Summary from './summary';

const Cart = () => (
  <>
    <MenuSide onlyMobile showSearch />
    <main className="w-full px-4 py-4 m-2">
      <Summary />
    </main>
  </>
);

export default Cart;
