import React, { useEffect, useState, useRef, useContext } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { trackPromise, manuallyIncrementPromiseCounter, manuallyResetPromiseCounter } from 'react-promise-tracker';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import STable from '../../components/grid/stable';
import SColumn from '../../components/grid/scolumn';

import { SpinnerAbsolute } from '../../components/spinner';

import { GlobalStateContext } from '../../components/context/global';

import CartContext, { useCart } from '../../components/context/cart.ts';

import {
  numberFormatCurrency, numberFormatVat, parseJSON, debounce, hashCode, compareDates, roundTo, isEmpty, sleep
} from '../../components/util';

import api from '../../components/service/api.ts';
import useCartAction from '../../components/hook/cartaction';
import InputCounter from '../../components/hook/inputcounter';
import LazyImage from '../../components/hook/lazyimage';

import CartSelect from '../../components/cart/cartselect';
import CartImport from '../../components/cart/cartimport';
import NormalDialog from '../../components/modal/normal';
import ForwardDialog from '../../components/modal/forward';
import useFileCache from '../../components/hook/useFileCache.ts';
// import useDidMountEffect from '../../components/hook/useDidMountEffect';
import PropExpand from '../../components/propselect/expand';

import Note from './note';
import AlertDialog from '../../components/modal/AlertDialog';

import CalcProduct from './CalcProduct';
import ValidProduct from './ValidProduct.tsx';

import IconDiscount from '../../components/icon/IconDiscount';

import './select.css';
import SelectNormal from '../../components/propselect/selectnormal';
import SelectCalendar from '../../components/propselect/selectcalendar';
import SelectDelivery from './selectdelivery';
import InputFile from './InputFile.tsx';
import ExportToPdf from './ExportToPdf';
import TextareaNote from './TextareaNote.tsx';
import InputSave from '../../components/InputSave';

let timeoutSummary = null;

const sendOrder = async (id) => {
  const resp = await api.sendOrder(id);
  return resp?.data;
};

const ObjSettCart = {
  show_completion_date: false,
  show_delivery_method: false,
  show_file_upload: true,
  cart_weight: 0,
  cart_summary: 0,
  cart_currency: "zł",
  third_side: { name: '' },
  logistic_min: { value: 0, info: ""},
};

const ObjDataCart = {
  id: 0,
};

const Summary = () => {
  const globalState = useContext(GlobalStateContext);
  const [cartState, cartAction] = useCartAction();
  const [, cartHook] = useCart();
  const history = useHistory();
  const { t } = useTranslation("cart");

  const [dataMain, setDataMain] = useState(null);
  const [dataCart, setDataCart] = useState({ ...ObjDataCart });
  const [formCart, setFormCart] = useState({});
  const [settCart, setSettCart] = useState({ ...ObjSettCart }); // setttings of cart
  const [loading, setLoading] = useState(0);
  const [activeOrder, setActiveorder] = useState(true);
  const [statusData, setStatusData] = useState([]);
  const [dataProperty, setDataProperty] = useState([]);

  const refFormExt = useRef();

  const changeActiveOrder = (order) => {
    setActiveorder(order);
  };

  const getCartList = async () => {
    const select = cartState.cart.selectNormal;
    // const resp = await trackPromise(api.getCart(select, 'Z'), 'cart-list');
    const resp = await cartAction.getCart(select, 'Z');
    return resp;
  };

  const getCartElementList = async (nagId) => {
    // const resp = await trackPromise(api.getCartElement(nagId), 'cart-list');
    const resp = await cartAction.getCartElement(nagId);
    return resp;
  };

  const getArticleProperty = async (symbolList) => {
    const resp = await api.getArticleProperties(symbolList);
    return resp?.data;
  };

  const getCartForm = async () => {
    const resp = await api.getCartForm();
    return resp?.data;
  };

  const getStatusData = (pozId) => {
    const data = statusData.find((val) => val.poz_id == pozId);
    if (data === undefined) return 0;

    let status = 0;
    status += data?.actualStock !== undefined ? 1 : 0;
    status += data?.actualPrice !== undefined ? 2 : 0;

    return status;
  };

  const setThirdSide = async (dataTS) => {
    manuallyIncrementPromiseCounter('cart-list');
    const cartId = cartState.cart.selectNormal;
    const isSuccess = await cartAction.setCartThirdSide(cartId, dataTS);
    manuallyResetPromiseCounter('cart-list');

    if (!isSuccess) {
      AlertDialog({
        title: 'Ostrzeżenie',
        message: 'Nie udało się dodać adresu dostawy',
      });

      return;
    }

    setSettCart({ ...settCart, third_side: dataTS });
  };

  const getData = () => {
    console.log("GETDATA", "summary");
    setLoading(1);
    // setDataCart({});
    const dataList = [];

    new Promise((resove) => resove(1)).then(async () => {
      // CART FORM
      const _dataForm = await getCartForm();
      if (_dataForm?.status !== 0) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania form koszyka.\u00A0${_dataForm?.info || ""}`,
          color: 'yellow',
        });
        return false;
      }

      const newFormList = parseJSON(_dataForm.dane);
      setFormCart(Object.assign(formCart, newFormList));

      // CART LIST
      const _dataCart = await getCartList();

      if (_dataCart?.err !== null) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania nagłówków koszyka.\u00A0${_dataCart?.err || ""}`,
          color: 'yellow',
        });
        return false;
      }

      const cartList = _dataCart.data;
      // console.log('CART LIST', cartList, (cartList[0]?.id) > 0);

      if (cartList?.cart == undefined || !(cartList?.cart[0]?.id > 0)) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieprawidłowy ID koszyka. Spróbuj ponownie.`,
          color: 'yellow',
        });
        return false;
      }

      const newDataCart = Object.assign({}, { ...cartList.cart[0] });

      // termin_r
      if (String(newDataCart?.termin_r).toDateInput() !== '1900-01-01' 
        && compareDates(String(newDataCart?.termin_r).toDateInput()) == -1
      ) {
        newDataCart["termin_r"] = (new Date()).toDateInput();
      }

      setDataCart({ ...newDataCart });

      // get cart elements
      const _dataCartElem = await getCartElementList(cartList.cart[0].id);
      // console.log('ELEM LIST', dataCartElem);

      if (_dataCartElem?.err !== null) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania pozycji koszyka.\u00A0${_dataCartElem?.err || ""}`,
          color: 'yellow',
        });
        return false;
      }

      const newDataList = _dataCartElem.data;
      if (!Array.isArray(newDataList) || newDataList.length == 0) {
        // poz list empty, skip after code
        return false;
      }

      dataList.push(...newDataList);
      // const artList = dataList.map(el => el.symbol_art); // array for api

      // show_completion_date
      const newSettCart = {...ObjSettCart};

      if (cartList?.show_completion_date == true || cartList?.show_completion_date == undefined) {
        newSettCart.show_completion_date = true;
      }

      // show_delivery_method
      if (cartList?.show_delivery_method == true || cartList?.show_delivery_method == undefined) {
        newSettCart.show_delivery_method = true;
      }

      // third_side
      if (Array.isArray(cartList?.third_side) && cartList?.third_side.length > 0) {
        newSettCart.third_side = cartList?.third_side[0];
      }

      // logistic_min
      if (cartList?.logistic_min != undefined) {
        newSettCart.logistic_min = { value: cartList.logistic_min?.value || 0, info: cartList.logistic_min?.info || "" };
      }

      if (cartList?.cart_weight > 0) {
        newSettCart.cart_weight = +cartList?.cart_weight;
      }

      if (dataList.length > 0) {
        newSettCart.cart_currency = dataList[0]?.currency || "PLN";
        newSettCart.cart_summary = dataList.reduce((acc, el) => acc + parseFloat(el.cena * el.ilosc_jm), 0).toFixed(2);
      }

      setSettCart({ ...newSettCart });

      // new tmp_stock
      dataList.map(art => {
        art.zapas = { actual: art.tmp_stock };
        art._status = getStatusData(art.poz_id);
        return art;
      });

      const _dataProperty = await getArticleProperty(dataList.map(art => art.symbol_art));
      if (_dataProperty?.status !== 0) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania cech do pozycji koszyka.\u00A0${_dataProperty?.info || ""}`,
          color: 'yellow',
        });
        return false;
      }

      const newPropertyList = parseJSON(_dataProperty.dane);
      if (newPropertyList !== false ) {
        setDataProperty(newPropertyList);
      }

      return true;
    }).then((flag) => {
      if (!flag) setDataCart({});
    }).finally(() => {
      setDataMain(dataList);
      setLoading(0);
    });
  };

  const changeData = () => {
    if (timeoutSummary != null) clearTimeout(timeoutSummary);
    timeoutSummary = setTimeout(() => {
      document.activeElement.blur();
      getData();
    }, 500);
  };

  // useEffect(() => {
  //   if (timeoutSummary != null) clearTimeout(timeoutSummary);
  // }, [cartState.loading]);

  useEffect(() => {
    console.log('FIRE summary');
    if (loading > 0) return;

    changeData();
  }, [cartState.select, cartState.product.event, statusData]);
  // }, [cartState.select, statusData]);
  // }, [cartState.product.event]);
  // }, []);

  const saveFormAll = async function() {
    manuallyIncrementPromiseCounter('cart-list');
    const listField = refFormExt.current.querySelectorAll("input[type='hidden'], input[name='nr_dok'], input[name='termin_r'], textarea");

    const paramList = Array
      .from(listField)
      .reduce((cum, el) => (
        { ...cum, [el.name]: el.value }
      ), {});

    const status = await cartAction.setCartExtension(cartState.cart.selectNormal, paramList);
    // Fix: debounce with callback prevent
    if (status) {
      const cartExt = cartAction.getCartExtension(cartState.cart.selectNormal);
      setDataCart(Object.assign(dataCart, { ...cartExt }));
    }

    manuallyResetPromiseCounter('cart-list');
    return status;
  };

  const changeForm = () => debounce(_ => {
    // saveForm(e.target.name, e.target.value);
    const activeElem = document.activeElement;
    activeElem.blur();
    saveFormAll();
    activeElem.focus();
  }, 600)([]);

  const openModalForPattern = (content = 'Dodano nowy wzorzec') => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const goModal = () => {
      history.push('/pattern');
      closeModal();
    };

    render(
      <ForwardDialog
        title="Informacja"
        open
        onClose={closeModal}
        onGoTo={goModal}
        textGoTo="Przejdź do wzorca"
      >
        {content}
      </ForwardDialog>,
      container,
    );
  };

  const copyToPattern = async () => {
    // console.log('copy to pattern');

    const cartCount = 1 + cartState.cart.filterAsTyp('W').length;
    const cartId = await cartAction.newPatternCopy(`Wzorzec ${cartCount}`, cartState.cart.selectNormal);

    if (cartId > 0) {
      const template = (
        <>
          Dodano nowy wzorzec: <b>Wzorzec {cartCount}</b>
        </>
      );
      openModalForPattern(template);
    }

    // console.log('pattern cartId', cartId);
  };

  const openModalForProduct = (order, productList, title = '') => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    const confirmModal = () => {
      cartHook.getWholeCart().catch(err => {
        console.log('CART', err);
      });
      closeModal();
    };

    render(
      <ValidProduct
        title={title || "Aktualizacja produktów w zamówieniu"}
        order={order}
        list={productList}
        onClose={closeModal}
        onConfirm={confirmModal}
      />, container,
    );
  };

  const openModalForOrder = (dataOrder) => {
    let info = '';

    if (dataOrder?.status === 0) {
      info = String(dataOrder.dane).replaceAll("\"", "");
    } else {
      info = dataOrder.info;
    }

    const container = document.getElementById('show-modal');

    cartHook.getWholeCart().catch(err => {
      console.log('CART', err);
    });

    const closeModal = () => {
      unmountComponentAtNode(container);
    };

    render(
      <NormalDialog
        title="Informacja"
        open={true}
        onClose={closeModal}
      >
        {dataOrder?.status != 0 ? 'Błąd zamówienia' : 'Zamówienie zostało zarejestrowane pod nr'}: <b>{info}</b>
      </NormalDialog>,
      container,
    );
  };

  const getOrderNumber = () => {
    if (dataCart?.klucz_dok != undefined && dataCart?.klucz_dok != "")
      return `${dataCart?.klucz_dok}`;

    return '';
  };

  const genOrder = async (e) => {
    e.preventDefault();
    if (dataMain === null || dataMain.length === 0) return;

    if (refFormExt.current === undefined) {
      AlertDialog({
        title: 'Błąd zamówienia',
        message: 'Brak referencji do formularza, aby wysłać zamówienie',
      });
      return;
    }

    setLoading(2);

    if (saveFormAll() == false) {
      setLoading(0);
      return;
    }

    getCartList().then(async (_dataCart) => {
      if (_dataCart?.err !== null) {
        AlertDialog({
          title: 'Ostrzeżenie',
          message: `Nieudana próba pobrania nagłówków koszyka.\u00A0${_dataCart?.err || ""}`,
          color: 'yellow',
        });
        return;
      }

      const cartList = { ..._dataCart.data };

      if (!cartList || !cartList?.cart) {
        AlertDialog({
          title: 'Błąd zamówienia',
          message: 'Nieprawidłowe dane koszyka. Proszę skontaktować się z administratorem',
        });
        return;
      }

      const cartId = parseInt(cartList.cart[0]?.id, 10) || 0;

      if (cartId === 0) {
        AlertDialog({
          title: 'Błąd zamówienia',
          message: 'Nieprawidłowy identyfikator koszyka. Proszę skontaktować się z administratorem',
        });
        return;
      }

      const dataOrder = await sendOrder(parseInt(cartList.cart[0]?.id, 10));

      if (dataOrder?.status == 0) {
        openModalForOrder(dataOrder);
        return;
      }

      console.log('Order err', dataOrder);

      // api prevent: status = 4 when validate error exists
      const dataValidate = parseJSON(dataOrder?.dane);
      if (dataValidate != false && Array.isArray(dataValidate?.validateErrors))
        dataOrder["status"] = 4;

      if (dataOrder?.status == 4) {
        // {"order":"ZS/B2B/22/000000","validateErrors":[{"actualStock": 0, "actualPrice":0,"poz_id":0}]}

        const dataProduct = dataValidate.validateErrors.map(val => {
          if (dataMain != null && dataMain.length > 0) {
            const product = dataMain.find(el => el.id == val.poz_id);
            if (product != undefined)
              return {...val, ...product};
          };

          return val;
        });

        /* setData status */
        if (dataProduct.length > 0) {
          setStatusData(dataProduct);
        }
        openModalForProduct(dataValidate.order, dataProduct);
      }

      if (dataOrder?.status == 3) {
        if (dataOrder?.info != "") {
          openModalForOrder(dataOrder);
          return;
        }

        openModalForProduct(dataCart?.klucz_dok, [], 'Potwierdzenie zamówienia');
      }
    }).finally(() => {
      setLoading(0);
    });
  };

  const quantityTemplate = (item) => {
    const [state, cartActionQ] = useCartAction();
    // TODO: problem with hermes api cart item.ilosc < 0
    const [quantity, setQuantity] = useState({ ...cartActionQ.getProduct({ item, symbolJM: item.symbol_jmp }), input: Math.max(0, item.ilosc) });
    const [firstRender, setFirstRender] = useState(true);
    // const [quantityMax, setQuantityMax] = useState({ current: 0, actual: 0 });
    const [quantityMax, setQuantityMax] = useState(cartAction.getQuantityMax({
        item,
        maxActual: item.zapas.actual,
        jmCurrent: item.symbol_jmp,
        stock_unlimit: globalState.user?.stock_unlimit,
      }));

    // const debounceQuantity = (__quantity) => debounce((_quantity) => {
    //   setLoading(true);
    //   // updateConfirm(_value);
    // }, 500)(__quantity);

    useEffect(() => {
      if (firstRender !== false) {
        setFirstRender(false);
        return;
      }

      if (quantity.input != quantity.confirm) {
        return;
      }

      cartActionQ.setProduct({
        item,
        symbolJM: item.symbol_jmp,
        quantity,
        force: false /* force */,
        price: !!globalState.user?.is_han ? item.cena : 0, /* newPrice */
        poz: item.poz_id,
      });

    }, [quantity]);

    return (
      <div className={`flex flex-row justify-center md:justify-start`}>
        <CartContext.Provider value={[quantity, setQuantity, state.loading, state.error, state.done]}>
          <InputCounter
            value={1}
            max={quantityMax.current}
            maxActual={quantityMax.actual}
            autoConfirm="true"
          />
        </CartContext.Provider>
      </div>
    );
  };

  const priceNettoTemplate = (item) => {
    const [showCalcProduct, setShowCalcProduct] = useState(false);
    const quantity = { ...cartAction.getProduct({ item, symbolJM: item.symbol_jmp }), input: Math.max(0, +item.ilosc) };

    const updateCalcProduct = (price) => {
      setShowCalcProduct(false);
      if (price <= 0) return;

      cartAction.setProduct({
        item,
        symbolJM: item.symbol_jmp,
        quantity,
        force: true /* force */,
        price, /* newPrice */
        poz: item.id,
      });
    };

    return (
      <div className='flex flex-col justify-end'>
        {+item.cena_nom > 0 && Number.parseFloat(+item.cena_nom).toFixed(2) != +item.cena && (
          <span
            className={`block text-xs text-red-400 leading-3 text-right items-right ${globalState.user?.price_change === true ? 'pr-8' : 'pr-2'}`}
            translate="no"
          >
            {numberFormatCurrency(+item.cena_nom * (item?.przelicz || 1), item?.currency)}
          </span>
        )}
        <div className="flex flex-row items-center justify-end gap-2" translate="no">
          <span className="flex items-center justify-end gap-1">
            {quantity.price_discount > 0 && roundTo(+quantity.price_discount, 2) !== roundTo(item.cena, 2) && (
              <span>
                <IconDiscount />
              </span>
            )}
            <span>
              {numberFormatCurrency(item.cena * (item?.przelicz || 1), item?.currency)}
            </span>
          </span>
          {globalState.user?.price_change === true && (
            <>
              <button
                type="button"
                className='w-4 h-4 outline-none focus:outline-none'
                onClick={() => setShowCalcProduct(true)}
              >
                <svg
                  className={`${showCalcProduct == true && 'hidden'}`}
                  viewBox="0 0 424 424"
                  style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit:2 }}
                >
                  <g>
                    <path style={{fill: "none", fillRule: "nonzero"}} d="M186.95,343.077L147.583,354.439L294.251,354.439C300.88,354.439 306.251,359.812 306.251,366.439C306.251,373.066 300.88,378.439 294.251,378.439L66.541,378.439C65.898,378.439 65.273,378.374 64.658,378.276C64.015,378.381 63.371,378.439 62.73,378.439C59.732,378.439 56.798,377.315 54.546,375.217C51.46,372.34 50.111,368.054 50.993,363.929L78.927,233.331C79.411,231.067 80.54,228.992 82.177,227.355L228.255,81.277L26.412,81.277L26.412,399.278L344.413,399.278L344.413,187.728L192.108,340.033C190.673,341.467 188.899,342.514 186.95,343.077Z" />
                    <path style={{fill: "none", fillRule: "nonzero"}} d="M163.321,324.917L97.879,259.474L78.654,349.352L163.321,324.917Z" />
                    <path style={{fill: "none", fillRule: "nonzero"}} d="M327.089,24C322.167,24 318.161,25.6 314.485,29.035C314.34,29.172 314.192,29.303 314.042,29.43L305.376,38.096L382.739,115.458L391.21,106.988C391.251,106.946 391.294,106.906 391.335,106.865L391.421,106.78C391.544,106.635 391.671,106.492 391.802,106.352C407.984,89.034 380.118,58.569 371.193,49.645C354.897,33.346 338.821,24 327.089,24Z" />
                    <path style={{fill: "var(--primary)", fillRule: "nonzero"}} d="M107.91,235.564L185.272,312.926L365.768,132.428L288.405,55.065L107.91,235.564ZM309.38,111.454C314.066,116.14 314.066,123.738 309.38,128.424L182.606,255.197C180.262,257.539 177.192,258.711 174.12,258.711C171.05,258.711 167.979,257.54 165.636,255.197C160.95,250.51 160.95,242.912 165.636,238.226L292.409,111.454C297.095,106.768 304.694,106.768 309.38,111.454Z"/>
                    <path style={{fill: "#4B5563", fillRule: "nonzero"}} d="M165.636,255.197C167.98,257.54 171.05,258.711 174.12,258.711C177.192,258.711 180.262,257.539 182.606,255.197L309.38,128.424C314.066,123.737 314.066,116.139 309.38,111.454C304.695,106.768 297.095,106.768 292.409,111.454L165.636,238.226C160.95,242.913 160.95,250.51 165.636,255.197Z" />
                    <path style={{fill: "#4B5563", fillRule: "nonzero"}} d="M417.848,76.476C413.213,62.604 402.95,47.457 388.162,32.673C373.259,17.768 350.565,0 327.089,0C316.558,0 306.855,3.703 298.954,10.721C298.542,11.037 298.149,11.382 297.778,11.753L252.253,57.277L14.412,57.277C7.785,57.277 2.412,62.65 2.412,69.277L2.412,411.278C2.412,417.905 7.785,423.278 14.412,423.278L356.413,423.278C363.04,423.278 368.413,417.905 368.413,411.278L368.413,166.123C368.413,165.375 368.335,164.647 368.204,163.937L408.057,124.083C408.137,124.004 408.217,123.926 408.295,123.846C408.297,123.845 408.299,123.843 408.301,123.841L409.082,123.059C409.451,122.69 409.789,122.305 410.099,121.902C421.012,109.661 423.694,93.979 417.848,76.476ZM163.321,324.917L78.654,349.353L97.879,259.475L163.321,324.917ZM185.272,312.926L107.91,235.564L288.405,55.066L365.768,132.429L185.272,312.926ZM344.413,399.278L26.412,399.278L26.412,81.277L228.255,81.277L82.178,227.355C80.541,228.992 79.412,231.067 78.928,233.331L50.994,363.928C50.111,368.053 51.461,372.339 54.547,375.216C56.799,377.314 59.733,378.438 62.731,378.438C63.219,378.438 62.731,378.438 62.731,378.438C65.994,378.438 104.729,368 101,368L147.583,354.438L186.95,343.076C188.899,342.513 190.673,341.466 192.108,340.032L344.413,187.727L344.413,399.278ZM391.802,106.351C391.671,106.491 391.544,106.634 391.421,106.779L391.335,106.864C391.294,106.905 391.251,106.946 391.21,106.987L382.739,115.457L305.376,38.095L314.042,29.429C314.192,29.302 314.341,29.171 314.485,29.034C318.161,25.6 322.167,24 327.089,24C338.821,24 354.898,33.346 371.192,49.644C380.118,58.568 407.983,89.033 391.802,106.351Z" />
                  </g>
                </svg>
                <svg className={`${showCalcProduct != true && 'hidden'} animate-spin text-primary`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
              </button>
              {showCalcProduct == true && (
                <CalcProduct
                  title={item.label}
                  quantity={quantity}
                  handleClose={() => setShowCalcProduct(false)}
                  handleUpdate={(_, price) => updateCalcProduct(price)}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const priceBruttoTemplate = (item) => (
    <span translate='no'>{numberFormatCurrency(item.cena * (item?.przelicz || 1) * numberFormatVat(item?.tax_rate), item.currency)}</span>
  );

  const priceNettoValueTemplate = (item) => (
    <span translate='no'>{numberFormatCurrency(item.cena * (item?.przelicz || 1) * Math.max(0, item.ilosc), item.currency)}</span>
  );

  const priceBruttoValueTemplate = (item) => (
    <span translate='no'>{numberFormatCurrency(item.cena * (item?.przelicz || 1) * numberFormatVat(item?.tax_rate) * Math.max(0, item.ilosc), item.currency)}</span>
  );

  const deleteArt = async (item, e) => {
    // console.log('SUMMARY DEL POZ', item);
    // cartAction.setMeasure(item, item.symbol_jmp);
    cartAction.setProduct({
      item,
      symbolJM: item.symbol_jmp,
      quantity: { input: 1, confirm: 0 },
      force: true,
      poz: item.poz_id,
    });
      
    e.currentTarget.closest("tr").style="transform:translateX(100%);transition:transform 0.5s 0s";
    await sleep(500);

    // console.log("Elem Del", e.currentTarget.closest("tbody"));
    if (dataMain !== null) {
      setDataMain(dataMain.filter(el => el.id != item.id));
    }
  };

  const deleteTemplate = (item) => (
    <>
      <button
        type="button"
        className="mt-1.5 text-red-600 bg-white border border-transparent rounded-full hover:border-red-600"
        onClick={(e) => deleteArt(item, e)}
        data-title={item.poz_id}
      >
        <svg className="w-5 h-5 border-2 border-transparent rounded-full" viewBox="0 0 24 24" fill="currentColor">
          <path d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 
            4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5
            15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z"
          />
          <path d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z" />
        </svg>
      </button>
    </>
  );

  const measureTemplate = (item) => (
    <span>
      {item.symbol_jmp}
    </span>
  );

  const lpTemplate = (_, index) => {
    return <span>{parseInt(index + 1, 10)}.</span>
  };

  const vatTemplate = (item) => (
    <>
      {!isEmpty(item?.tax_lbl) && (
        <span>
          {item?.tax_lbl || "0%"}
        </span>
      )}
      {isEmpty(item?.tax_lbl) && (
        <span>
          {parseInt(item?.tax_rate == undefined ? 23 : item.tax_rate == 1 ? 0 : item.tax_rate, 10)}%
        </span>
      )}
    </>
  );

  const nameTemplate = (item) => {
    const [_, cartActionQ] = useCartAction();
    const [loadArticleImage] = useFileCache();
    const [property, setProperty] = useState({ definition: [], selection: []});
    const [note, setNote] = useState(item.opis);

    const saveNote = async (noteDesc) => {
      const status = await cartActionQ.setDescription(item, item.id /* pozId */, noteDesc);

      if (status) {
        item.opis = noteDesc;
        setNote(noteDesc);
      }

      console.log ('NOTE', status);
    };

    const generateProperty = () => {
      const propertyDef = dataProperty.find(el => el.art == item.symbol_art);
      if (propertyDef == undefined) return;

      const propertyDefinition = propertyDef.cechy;
      const propertySelection = item.cechy.length > 0 ? parseJSON(item.cechy) || [] : [];

      setProperty({ definition: propertyDefinition, selection: propertySelection });
    };

    useEffect(() => {
      generateProperty();
    }, []);

    return (
      <>
        <div className="flex flex-col items-center md:flex-row md:items-start">
          <picture className="md:mr-2">
            <Link to={`/article/show/${encodeURIComponent(item.symbol_art)}`}>
              <LazyImage
                className="w-10 h-10 min-w-10"
                src=""
                symbol={item.symbol_art}
                width="50"
                height="50"
                alt=""
                handlerLoad={(symbol) => loadArticleImage(symbol, 50)}
              />
            </Link>
          </picture>
          <div className="flex-grow">
            <div className="text-sm font-medium leading-5 text-center text-gray-900 md:text-left">
              <Link to={`/article/show/${encodeURIComponent(item.symbol_art)}`}>
                <span className="">{item.nazwa_art}</span>
                <span className="pl-2 text-sm leading-5 text-gray-400">({item.label})</span>
              </Link>
            </div>
            {property.definition.length > 0 && (
              <div className='text-sm'>
                <PropExpand item={item} property={property} />
              </div>
            )}
            <div className="flex flex-col items-center md:items-start">
              <Note text={note} updateHandler={(content) => saveNote(content)} updateActiveOrderButton={(stat) => changeActiveOrder(stat)} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const priceSummaryTemplate = (items, len) => {
    const priceSum = {
      netto: items.reduce((prev, curr) => prev + (curr.cena * (curr?.przelicz || 1) * Math.max(0, curr.ilosc)), 0),
      brutto: items.reduce((prev, curr) => prev + (curr.cena * (curr?.przelicz || 1) * numberFormatVat(curr?.tax_rate) * Math.max(0, curr.ilosc)), 0),
    };

    return (
      <React.Fragment key={`cart_row_summary`}>
        {settCart.cart_weight > 0 && (
          <td className="px-4 py-3 text-left whitespace-no-wrap border-t border-b border-gray-200 bg-gray-50 md:ml-3" colSpan={3}>
            {t('Waga')}: <b className='font-medium'>{settCart.cart_weight}&nbsp;kg</b>
          </td>
        )}
        <td colSpan={settCart.cart_weight > 0 ? len - 5 : len - 2} className="px-4 py-3 text-right whitespace-no-wrap border-t border-b border-gray-200 bg-gray-50 md:ml-3">
          <span>{t('Razem')}:</span>
        </td>
        <td className="px-4 py-3 text-right whitespace-no-wrap border-t border-b border-gray-200 bg-gray-50 md:ml-3" translate="no" data-name="Wartość netto">
          <b className='font-medium'>{numberFormatCurrency(priceSum.netto, items.length > 0 && items[0]?.currency)}</b>
        </td>
        <td className="px-4 py-3 text-right whitespace-no-wrap border-t border-b border-gray-200 bg-gray-50 md:ml-3" translate="no" data-name="Wartość brutto">
          <b className='font-medium'>{numberFormatCurrency(priceSum.brutto, items.length > 0 && items[0]?.currency)}</b>
        </td>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="relative">
        {loading > 0 && (
          <SpinnerAbsolute isFixed />
        )}
        <div className="flex flex-row flex-wrap mb-4">
          <span className="mb-2 mr-2">
            <CartSelect />
          </span>
          <CartImport />
          {(dataMain != null && dataMain.length > 0) && (
            <button
              type="button"
              className="p-2 px-3 mb-2 mr-2 text-sm leading-4 border border-gray-300 rounded shadow-sm border-1 hover:bg-gray-800 hover:text-white hover:border-transparent"
              onClick={copyToPattern}
              data-cy="export-to-pattern-button"
            >
              <svg className="inline w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="-477 -477 477 477" fill="currentColor">
                <g transform="rotate(180)">
                  <path d="M409.6,153.6h-85.333c-9.426,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067H409.6
                    c9.426,0,17.067,7.641,17.067,17.067v221.867c0,9.426-7.641,17.067-17.067,17.067H68.267c-9.426,0-17.067-7.641-17.067-17.067
                    V204.8c0-9.426,7.641-17.067,17.067-17.067H153.6c9.426,0,17.067-7.641,17.067-17.067S163.026,153.6,153.6,153.6H68.267
                    c-28.277,0-51.2,22.923-51.2,51.2v221.867c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2V204.8
                    C460.8,176.523,437.877,153.6,409.6,153.6z"
                  />
                  <path d="M335.947,243.934c-6.614-6.387-17.099-6.387-23.712,0L256,300.134V17.067C256,7.641,248.359,0,238.933,0
                    s-17.067,7.641-17.067,17.067v283.068l-56.201-56.201c-6.78-6.548-17.584-6.361-24.132,0.419c-6.388,6.614-6.388,17.1,0,23.713
                    l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.03c0.01-0.01,0.02-0.02,0.031-0.03l85.333-85.333
                    C342.915,261.286,342.727,250.482,335.947,243.934z"
                  />
                </g>
              </svg>
              {t('Eksportuj do wzorca')}
            </button>
          )}
          {(dataMain != null && dataMain.length > 0) && (
            <ExportToPdf defaultMessage={t('Eksportuj do PDF')} />
          )}
          <div className="flex justify-start flex-grow mb-2 md:justify-end">
            <button
              type="button"
              data-testid="gorne_menu-zloz_zamowienie"
              className={`${dataMain == null || dataMain.length === 0 || activeOrder === false ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : ' bg-primary hover:bg-gray-800 '} border border-transparent border-px px-3 py-2 text-white rounded text-sm flex flex-row `} 
              onClick={genOrder}
              disabled={!activeOrder}
            >
              <svg className={`${loading == 2 ? '' : 'hidden'} animate-spin mr-3 h-5 w-5 text-white`}  fill="none" viewBox="0 0 24 24">
                <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              <svg className={`${loading != 2 ? '' : 'hidden'} h-3 w-3 mr-2 mt-1 text-white`} fill="currentColor" viewBox="0 -46 417.81333 417">
                <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
              </svg>
              <span title={getOrderNumber()}>{t('Złóż zamówienie')}</span>
            </button>
          </div>
        </div>
        {dataMain != null && dataMain.length > 0 && (
          <div className="relative min-w-full pb-2 align-middle">
            <STable
              keyName={`cart_summary_${dataMain.length}`}
              data={dataMain || []}
              summary={priceSummaryTemplate}
            >
              <SColumn
                className="px-4 py-3 md:ml-3 whitespace-no-wrap w-0.5 text-center md:text-left"
                template={deleteTemplate}
              />
              <SColumn
                name={t("Lp")}
                className="px-4 py-3 md:ml-3 whitespace-no-wrap w-0.5 hidden md:table-cell"
                template={lpTemplate}
              />
              <SColumn
                name={t("Nazwa / Symbol")}
                symbol="nazwa_art"
                className="items-center w-1/3 px-4 py-3 whitespace-no-wrap md:ml-3 name-hidden"
                template={nameTemplate}
              />
              <SColumn
                name={t("JM")}
                symbol="symbol_jm"
                className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-left"
                template={measureTemplate}
              />
              <SColumn
                name={t("Ilość")}
                symbol=""
                className="justify-start w-3 px-4 py-3 whitespace-no-wrap md:ml-3 name-hidden"
                // classHeader="text-right uppercase px-4 py-3"
                template={quantityTemplate}
              />
              <SColumn
                name={t("Cena netto")}
                className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
                classHeader="text-right uppercase px-4 py-3"
                template={priceNettoTemplate}
              />
              <SColumn
                name={t("Cena brutto")}
                className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
                classHeader="text-right uppercase px-4 py-3"
                typeColumn="currency"
                defaultValue="0"
                template={priceBruttoTemplate}
              />
              <SColumn
                name={t("VAT")}
                className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
                classHeader="text-right uppercase px-4 py-3"
                template={vatTemplate}
              />
              <SColumn
                name={t("Wartość netto")}
                className="w-3 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
                classHeader="text-right uppercase px-4 py-3"
                typeColumn="currency"
                defaultValue="0"
                template={priceNettoValueTemplate}
              />
              <SColumn
                name={t("Wartość brutto")}
                className="w-6 px-4 py-3 text-center whitespace-no-wrap md:ml-3 md:text-right"
                classHeader="text-right uppercase px-4 py-3"
                typeColumn="currency"
                defaultValue="0"
                template={priceBruttoValueTemplate}
              />
            </STable>
          </div>
        )}
        {dataMain == null || dataMain.length == 0 && (
          <div className="relative px-4 py-3 mb-2 text-center text-yellow-700 transition bg-yellow-100 rounded" role="alert">
            <span className="block sm:inline">{t('Pusty koszyk')}</span>
          </div>
        )}
        {dataMain != null && dataMain.length > 0 && Object.keys(dataCart).length > 0 && (
          <>
            {(settCart.logistic_min.value > 0 && settCart.logistic_min.value > settCart.cart_summary) && (
              <div className='flex flex-row items-center justify-center w-full gap-2 p-1 border border-yellow-200 rounded gap-x-4 border-px bg-yellow-50'>
                <svg className='w-12 h-12' viewBox="0 0 512 512" fill="currentColor">
                  <path d="M311.069,130.515c-0.963-5.641-5.851-9.768-11.578-9.768H35.43c-7.61,0-13.772,6.169-13.772,13.765
                    c0,7.61,6.162,13.772,13.772,13.772h64.263c7.61,0,13.772,6.17,13.772,13.773c0,7.603-6.162,13.772-13.772,13.772H13.772
                    C6.169,175.829,0,181.998,0,189.601c0,7.603,6.169,13.764,13.772,13.764h117.114c6.72,0,12.172,5.46,12.172,12.18
                    c0,6.72-5.452,12.172-12.172,12.172H68.665c-7.61,0-13.772,6.17-13.772,13.773c0,7.602,6.162,13.772,13.772,13.772h45.857
                    c6.726,0,12.179,5.452,12.179,12.172c0,6.719-5.453,12.172-12.179,12.172H51.215c-7.61,0-13.772,6.169-13.772,13.772
                    c0,7.603,6.162,13.772,13.772,13.772h87.014l5.488,31.042h31.52c-1.854,4.504-2.911,9.421-2.911,14.598
                    c0,21.245,17.218,38.464,38.464,38.464c21.237,0,38.456-17.219,38.456-38.464c0-5.177-1.057-10.094-2.911-14.598h100.04
                    L311.069,130.515z M227.342,352.789c0,9.146-7.407,16.553-16.553,16.553c-9.152,0-16.56-7.407-16.56-16.553
                    c0-6.364,3.627-11.824,8.892-14.598h15.329C223.714,340.965,227.342,346.424,227.342,352.789z"/>
                  <path d="M511.598,314.072l-15.799-77.941l-57.689-88.759H333.074l32.534,190.819h38.42
                    c-1.846,4.504-2.904,9.421-2.904,14.598c0,21.245,17.219,38.464,38.456,38.464c21.246,0,38.464-17.219,38.464-38.464
                    c0-5.177-1.057-10.094-2.91-14.598h16.741c6.039,0,11.759-2.708,15.582-7.386C511.273,326.136,512.8,319.988,511.598,314.072z
                    M392.529,182.882h26.314l34.162,52.547h-51.512L392.529,182.882z M456.14,352.789c0,9.146-7.407,16.553-16.56,16.553
                    c-9.138,0-16.552-7.407-16.552-16.553c0-6.364,3.635-11.824,8.892-14.598h15.329C452.513,340.965,456.14,346.424,456.14,352.789z"
                    />
                </svg>
                <p className=''>
                  <span>{t('Do darmowej dostawy brakuje jeszcze')} </span>
                  <span className='font-semibold text-red-600'>
                    {numberFormatCurrency(settCart.logistic_min.value - settCart.cart_summary, settCart.cart_currency)}
                  </span>
                </p>
                <p className=''>
                  {settCart.logistic_min.info}
                </p>
              </div>
            )}
            <div className="flex flex-col w-full mt-2 gap-y-3" id="form_ext" ref={refFormExt}>
              <div className="flex flex-col flex-wrap md:flex-row gap-y-2">
                <div data-cy="payment-method-select-summary" className="md:mr-2">
                  {/* key={hashCode(JSON.stringify(dataCart?.symbol_fpl || ""))}> */}
                  <label className="block text-sm leading-8">{t('Formy płatności')}:</label>
                  <SelectNormal
                    name="symbol_fpl"
                    list={formCart?.formypl && formCart.formypl
                      .reduce((acc, curr) => {
                        if (acc.length == 0) return [curr];
                        if (acc.find(el => el.symbol_fpl == curr.symbol_fpl) != undefined) return acc;
                        acc.push(curr);
                        return acc;
                      }, [])
                      .map(el => (
                      {
                        key: `symbol_fpl`,
                        value: el.symbol_fpl,
                        label: el.nazwa_fpl,
                      }
                    ))}
                    defaultValue={dataCart?.symbol_fpl}
                    className={'min-w-[200px]'}
                    onChange={changeForm}
                  />
                </div>
                <div data-cy="delivery-address-select" className="md:mr-2">
                  <label className="block text-sm leading-8">{t('Miejsce dostawy')}:</label>
                  <SelectDelivery
                    name="symbol_odb"
                    list={formCart?.odbiorcy && formCart.odbiorcy
                      .reduce((acc, curr) => {
                        if (acc.length == 0) return [curr];
                        if (acc.find(el => el.symbol_odb == curr.symbol_odb) != undefined) return acc;
                        acc.push(curr);
                        return acc;
                      }, [])
                      .map(el => ({
                        key: `symbol_odb`,
                        value: el.symbol_odb,
                        label: <>
                          <b>{el.nazwa_odb}</b><br/>
                          {el.odb_ul} {el.odb_nr_dom}{el.odb_nr_lok.length > 0 ? <>/{el.odb_nr_lok}</> : ''}<br/>
                          {el.kod} {el.miejsc}
                          {el?.country != "" ? <><br/>{el.country}</> : ''}
                        </>,
                      }))
                      .sort((a, b) => a?.b2b_dflt > b?.b2b_dflt)
                    }
                    delivery={settCart?.third_side}
                    defaultValue={dataCart?.symbol_odb}
                    readOnly={!!globalState.user?.branch_odb}
                    fieldOption={{ 
                      visible: !!globalState.user?.branch_new, 
                      name: (
                        <span>
                          <Trans t={t} i18nKey={'_AddAddress'}>Dodaj <b>inny adres</b></Trans>
                        </span> 
                      )
                    }}
                    className={'min-w-[200px]'}
                    onChangeDelivery={setThirdSide}
                    onChangeSelect={changeForm}
                  />
                </div>
                {settCart.show_delivery_method && (
                  <div data-cy="pick-up-method-select" className="md:mr-2">
                    <label className="block text-sm leading-8">{t('Sposób\u00A0odbioru')}:</label>
                    <SelectNormal
                      name="odbior"
                      list={formCart?.sposobyodb && formCart.sposobyodb.filter(el => el.opis != "").map(el => (
                        {
                          key: `odbior`,
                          value: el.id,
                          label: el.opis,
                        }
                      ))}
                      defaultValue={dataCart?.odbior}
                      className={'min-w-[200px]'}
                      onChange={changeForm}
                    />
                  </div>
                )}
                {settCart.show_completion_date && (
                  <div data-cy="delivery-date-select" className="md:mr-2">
                    <label className="block text-sm leading-8">{t('Termin dostawy')}:</label>
                    <SelectCalendar
                      name="termin_r"
                      defaultValue={dataCart?.termin_r}
                      className={'min-w-[200px]'}
                      onChange={changeForm}
                      defaultMessage={t('Brak terminu')}
                    />
                  </div>
                )}
                <div className="md:mr-2">
                  <label className="block text-sm leading-8">{t('Numer dokumentu')}:</label>
                  <div className="relative max-w-sm">
                    <InputSave
                      className="w-full text-sm text-gray-600 bg-white border border-gray-300 shadow-sm rounded outline-none md:w-auto 
                        focus:outline-none ring-opacity-0 ring-0 focus:ring-1 min-w-[200px]"
                      name="nr_dok"
                      type="text"
                      dataCy="document-number"
                      maxLength={50}
                      defaultValue={dataCart?.nr_dok || ""}
                      onChange={changeForm}
                      keyHash={hashCode(dataCart?.nr_dok || "")}
                    />
                  </div>
                </div>
                {settCart.show_file_upload && (
                  <div className="md:mr-2">
                    <label className="block text-sm leading-8">{t('Załącznik')}:</label>
                    <InputFile
                      className="w-full text-sm text-gray-600 bg-white border border-gray-300 rounded shadow-sm outline-none md:w-auto focus:outline-none ring-opacity-0 ring-0 focus:ring-1"
                      name="file_pdf"
                      type="file"
                      data-cy="document-number"
                      maxLength={50}
                      defaultValue={dataCart?.id || 0}
                      onChange={changeForm}
                      key={hashCode("file_pdf")}
                      defaultMessage={t('Brak pliku')}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-row">
                <div className="w-full">
                  <label className="block text-sm leading-8">{t('Uwagi')}:</label>
                  <TextareaNote
                    className="w-full border border-gray-300 rounded shadow-sm md:w-auto md:max-w-300"
                    name="uwagi"
                    maxLength={65000}
                    defaultValue={dataCart?.uwagi}
                    onChange={changeForm}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex justify-start flex-grow mb-2 md:justify-end">
          <button
            type="button"
            data-testid="gorne_menu-zloz_zamowienie"
            className={`${dataMain == null || dataMain.length === 0 || activeOrder === false ? 'bg-gray-200 text-gray-500 cursor-not-allowed' : ' bg-primary hover:bg-gray-800 '} border border-transparent border-px px-3 py-2 text-white rounded text-sm flex flex-row `} 
            onClick={genOrder}
            disabled={!activeOrder}
          >
            <svg className={`${loading == 2 ? '' : 'hidden'} animate-spin mr-3 h-5 w-5 text-white`}  fill="none" viewBox="0 0 24 24">
              <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <svg className={`${loading != 2 ? '' : 'hidden'} h-3 w-3 mr-2 mt-1 text-white`} fill="currentColor" viewBox="0 -46 417.81333 417">
              <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 
                0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 
                15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0"
              />
            </svg>
            <span title={getOrderNumber()}>{t('Złóż zamówienie')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Summary;
