import { useState } from 'react';

const SRowDetails = ({ index, handleToggle = (child) => {} }) => {
  const [expand, setExpand] = useState(false);

  const toggleExpand = (e) => {
    setExpand(!expand);

    const trExpander = e.target.closest('tr').nextSibling;
    if (expand) {
      trExpander.classList.add('hidden');
      return;
    }

    handleToggle(trExpander.children[0]);
    trExpander.classList.remove('hidden');
  };

  return (
    <>
      <button
        data-symbol={`bt-${index}`}
        type="button"
        className="inline-flex items-center w-4 font-bold bg-grey-light hover:bg-grey text-grey-darkest focus:outline-none"
        onClick={toggleExpand}
      >
        <svg className={`relative ${expand ? 'transform rotate-90 left-0.5' : '-left-0.5'} h-4 w-4`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <g transform="scale(1.2,1.2)">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M9 5l7 7-7 7" />
          </g>
        </svg>
      </button>
    </>
  );
};

export default SRowDetails;
