import { useRef, useContext, useEffect, useCallback, useState } from 'react';
import CartContext from '../context/cart.ts';
import { debounce, toFixedCut } from '../util/index';
import PopWarning from '../PopWarning';
import './style.css';

const InputCounter = ({ value = 1, max = 0, maxActual = 0, disable = false, unavailable = false, resetValue = true, autoConfirm = false, incValue = true }) => {
  const [quantity, setQuantity, _loading, error] = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(0);
  const [prevValue, setPrevValue] = useState(value);
  const [refDiv, setRefDiv] = useState(null);
  const [showPopup, setShowPopup] = useState(-1);

  const refQuantity = useRef();
  const maxLimit = 1000000;

  // console.log('QUANTITYINPUT', quantity, _loading, error);

  if (typeof max === 'boolean') {
    max = max === true ? -1 : 0;
    // unavailable = max === true;
  } else if (max < 0) {
    max = max == -1 ? -1 : 0;
    // unavailable = max != -1;
  }

  if (value < 0) value = 0;

  useEffect(() => {
    if (refQuantity) {
      // refQuantity.current.attributes["type"].value = "text";
      // console.log("INPUT number", quantity.input, refQuantity.current.value);
      // const caretPos = refQuantity.current.selectionStart; // - refQuantity.current.selectionEnd;
      refQuantity.current.value = toFixedCut(parseFloat(quantity.input), quantity.precision);
      refQuantity.current.setSelectionRange(position, position);
      // refQuantity.current.attributes["type"].value = "number";
      showPopWarning();
    }

    if (!autoConfirm)
      setLoading(false);
  }, [quantity]);

  useEffect(() => {
    if (refQuantity.current == undefined) return;

    const maxConfirm = autoConfirm ? maxActual : max;

    if (maxConfirm > 0 && +quantity.input > maxConfirm) {
      quantity.input = parseFloat(maxConfirm);
      refQuantity.current.value = quantity.input;
    }

    if (+quantity.input > maxLimit) {
      quantity.input = parseFloat(maxLimit);
      refQuantity.current.value = quantity.input;
    }
  }, [maxActual]);

  useEffect(() => {
    // console.log("Loading context", _loading);

    _loading == false && setLoading(false);
  }, [_loading]);

  const updateConfirm = (_valueConfirm) => {
    if (!autoConfirm || disable) {
      setLoading(false);
      return;
    }

    // console.log('updateConfirm pre', quantity, _valueConfirm, max, maxActual);

    // autoConfirm
    if (max !== -1 && (+_valueConfirm) >= maxActual) {
      quantity.confirm = parseFloat(maxActual);
      setLoading(false);
    } else {
      quantity.confirm = parseFloat(+_valueConfirm);
    }

    if (quantity.confirm > maxLimit)
      quantity.confirm = maxLimit;

    quantity.input = quantity.confirm;

    // console.log('updateConfirm post', quantity);
    setQuantity({ ...quantity });
    // setLoading(false);
  };

  const debounceConfirm = (__value) => debounce((_value) => {
    // console.log('debounceInput', _value, _tValue);
    setLoading(true);
    updateConfirm(_value);
  }, 500)(__value);

  const debounceKey = (__func, __event) => debounce((_func, _event) => {
    _func(_event);
  }, 0)(__func, __event);

  const plus = () => {
    setLoading(true);
    let maxInput = parseInt(value, 10);

    if (max !== -1 && maxActual <= +quantity.input) {
      if (max != 0)
        maxInput = parseInt(maxActual, 10);
    } else
      maxInput = parseInt(+quantity.input + value, 10);

    if (maxInput > maxLimit)
      maxInput = maxLimit;

    return maxInput;
  };

  const minus = () => {
    setLoading(true);
    return parseInt(+quantity.input > value ? +quantity.input - value : value, 10);
  };

  const handleKeyUp = (e) => {
    // if (loading == true) return;
    // loading != true && setLoading(true);

    debounceKey(function(e) {
      // if (max === 0) return;
      let targetValue = e.target.value;
      if (targetValue === '') return;

      const keyCode = (e.keyCode ? e.keyCode : e.which);

      if (e.ctrlKey && keyCode == 65) { // CTRL + A
        e.target.select();
        return;
      }

      if (keyCode == 17) { // CTRL up
        return;
      }

      if (keyCode === 13) { // enter
        return;
      }

      if (keyCode == 110 || keyCode == 188 || keyCode == 190) { // NumpadDecimal, ',', '.')
        e.target.value = prevValue;
        targetValue = prevValue;
        e.target.selectionStart = prevValue.toString().indexOf('.') + 1;
        setPosition(e.target.selectionStart);
      } else {
        setPosition(e.target.selectionStart);
      }

      if (keyCode === 37 || keyCode === 39) { // arrow left right
        return;
      }

      const maxConfirm = autoConfirm ? maxActual : max;
      if (max !== -1 && maxConfirm <= +targetValue)
        targetValue = parseFloat(maxConfirm);

      if (quantity.precision > 0)
        value = Number(0).toFixed(quantity.precision).replace(/.$/, "1");

      if (!(+targetValue >= value))
        targetValue = value;

      const countDecimals = (function (_value) {
        if (Math.floor(_value) !== _value) {
          return _value.toString().split('.')[1]?.length || 0;
        }

        return 0;
      }(targetValue));

      if (countDecimals > quantity.precision)
        quantity.input = toFixedCut(parseFloat(targetValue), quantity.precision);
      else
        quantity.input = toFixedCut(parseFloat(targetValue), countDecimals);

      if (quantity.input > maxLimit) {
        quantity.input = targetValue = maxLimit;
      }

      e.target.value = targetValue;

      setQuantity({ ...quantity });
      debounceConfirm(quantity.input);
    }, e);
  };

  const handleKeyDown = (e) => {
    // if (loading == true) return;
    // loading != true && setLoading(true);

    debounceKey(function(e) {
      // if (max === 0) return;
      const targetValue = e.target.value;
      const keyCode = (e.keyCode ? e.keyCode : e.which);

      console.log('PREVENT INPUT PRE', keyCode, String.fromCharCode(keyCode));

      if (keyCode != 37 && keyCode != 39 && keyCode != 8 && keyCode != 46 && // backspace, delete
        // keyCode != 110 && keyCode != 188 && keyCode != 190 && // NumpadDecimal, ',', '.'
        (keyCode < 96 || keyCode > 105) && 
        (isNaN(String.fromCharCode(e.which)) && keyCode != 110 && keyCode != 188 && keyCode != 190)
      ) {
        console.log('PREVENT INPUT', e.key, e.code, String.fromCharCode(e.which), isNaN(String.fromCharCode(e.which)))
        e.preventDefault();
      }

      setPrevValue(targetValue);

      if (keyCode === 37 || keyCode === 39) {
        return;
      }

      if (keyCode === 107) { //e.key === '+') {
        e.preventDefault();
        quantity.input = plus();
        setQuantity({ ...quantity });
        debounceConfirm(quantity.input);
        return;
      }

      if (keyCode === 109) { //e.key === '-') {
        e.preventDefault();
        quantity.input = minus();
        setQuantity({ ...quantity });
        debounceConfirm(quantity.input);
        return;
      }

      if (e.key === 'Enter') {
        if (max !== -1 && (+targetValue) >= max) // maxActual = max quantity in input for jm
          quantity.confirm = parseFloat(maxActual);
        else
          quantity.confirm = parseFloat(+quantity.input) + parseFloat(quantity.confirm);

        if (quantity.confirm > maxLimit)
          quantity.confirm = maxLimit;

        if (resetValue) quantity.input = value;
        setQuantity({ ...quantity });
      }

      if (e.ctrlKey && e.key === 'Delete') {
        if (quantity.confirm === 0) return;

        quantity.confirm = 0;
        if (resetValue) quantity.input = value;
        setQuantity({ ...quantity });
      }
    }, e);
  };

  const changeValue = (val) => { // plus, minus
    if (quantity.input == val) {
      setLoading(false);
      return;
    }

    loading != true && setLoading(true);
    quantity.input = parseFloat(val);
    console.log('CHANGE VALUE', quantity, val);
    setQuantity({ ...quantity });
    debounceConfirm(quantity.input);
  };

  const handleBlur = (e) => {
    const validate = /^\d+(?:\.\d{1,2})?$/.test(e.target.value);
    if (!validate) {
      e.target.value = toFixedCut(parseFloat(quantity.input), quantity.precision);
    }

    // e.target.style.borderColor=/^\d+(?:\.\d{1,2})?$/.test(e.target.value)? 'inherit' : 'red' ;
  };

  const handleFocus = (e) => {
    // console.log("INPUT FOCUS", document.activeElement, e.target);
    e.target.select();
    // e.target.setSelectionRange(0, e.target.value.length);
  };

  const showPopWarning = () => {
    // const isShow = (max == quantity.input || max == quantity.confirm) && max > 0 && disable == false;
    const isShow = ((autoConfirm && maxActual <= quantity.input) || (!autoConfirm && max <= quantity.input)) && max > 0 && disable == false;

    if (isShow == true && showPopup == 0) {
      setShowPopup(Math.random() * 100);
      setTimeout(() => setShowPopup(0), 2500);
    }

    if (showPopup == -1) setShowPopup(0);
  };

  return (
    <div
      className="flex flex-wrap items-center"
      data-testid="InputCounter"
      // data-tooltip="podaj wartość w wymaganym formacie"
    >
      <div className="relative pr-2 custom-number-input h-9 w-30" ref={setRefDiv}>
        <div className={`flex flex-row h-9 w-full rounded-lg relative bg-white border border-gray-300 ${error === quantity.hash ? 'border-red-500' : loading ? 'rainbow-box' :  ''}`}>
          {/*
          <div className={`${loading ? 'block' : 'hidden' } absolute -top-0 right-9 h-8 bg-white`}>
            <img className="relative w-14 h-14" src="/assets/loading.gif" style={{ top: "-11px", left: "7px" }} />
          </div>
          */}
          <div className="w-10 p-1 rounded-l-lg" data-testid="InputCounter-sub">
            <button
              onClick={() => changeValue(minus())}
              type="button"
              disabled={disable || value >= quantity.input}
              className={`bg-gray-100 text-gray-500 hover:text-gray-700 hover:bg-gray-300 h-full w-8 rounded cursor-pointer 
                          focus:outline-none ${disable || value >= quantity.input ? 'cursor-not-allowed' : ''}`}
            >
              <span className="m-auto text-2xl font-light leading-3 outline-none">−</span>
            </button>
          </div>
          <input
            type="tel"
            title=""
            data-test={"" + quantity.input + " " + quantity.precision}
            ref={refQuantity}
            defaultValue={toFixedCut(parseFloat(quantity.input), quantity.precision)}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            step={`${quantity.precision > 0 ? "0." + "1".padStart(quantity.precision, "0") : "1"}`}
            pattern={`${quantity.precision > 0 ? "^\d*(\.\d{0,"+ quantity.precision + "})?$" : "^\d$"}`}
            onBlur={handleBlur}
            onFocus={handleFocus}
            validate="false"
            disabled={disable}
            className={`outline-none focus:outline-none px-0 border-0 text-center w-14 bg-white font-semibold text-sm text-gray-600 hover:text-black 
                        focus:text-black md:text-basecursor-default flex items-center focus:border-0 focus:shadow-none ring-opacity-0 
                        ring-0 focus:ring-2 ${disable ? 'cursor-not-allowed' : ''}`}
          />
          <div className="w-10 p-1 rounded-r-lg" data-testid="InputCounter-add">
            <button
              onClick={() => changeValue(plus())}
              type="button"
              disabled={disable || (!autoConfirm && max <= quantity.input && max != -1) || (autoConfirm && maxActual <= quantity.input && max != -1) || max == 0|| quantity.input >= maxLimit}
              onMouseOver={showPopWarning}
              className={`bg-gray-100 text-gray-500 hover:text-gray-700 hover:bg-gray-300 h-full w-8 rounded cursor-pointer 
                          focus:outline-none ${disable || (!autoConfirm && max <= quantity.input && max != -1) || (autoConfirm && maxActual <= quantity.input && max != -1) || max == 0 || quantity.input >= maxLimit ? 'cursor-not-allowed' : ''}`}
            >
              <span className="m-auto text-2xl font-light leading-3 outline-none">+</span>
            </button>
          </div>
        </div>
      </div>
      <PopWarning
        refElement={refDiv}
        isOpen={showPopup > 0}
        template={(`maksymalna ilość`)}
      />
    </div>
  );
};

export default InputCounter;
