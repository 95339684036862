import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const ArticleSwitch = ({ handleDisplayMode, initView }) => {
  const { t } = useTranslation("shop");
  const [mode, setMode] = useState(initView);

  const onChangeView = (view) => {
    setMode(view);
    typeof handleDisplayMode == "function" && handleDisplayMode(view);
  };

  return (
    <div className="inline-flex pl-4">
      {/* 
      <button
        type="button"
        onClick={() => onChangeView(2)}
        className="bg-gray-100 hover:bg-gray-200 text-gray-800 py-2 px-3 rounded-l"
      >
        <p className='rounded'>
          <svg className="w-4 h-4" viewBox="0 0 16 16" fill="currentColor">
            <path fillRule="evenodd" d="M0,1 L20,1 L20,3 L0,3 L0,3 Z M0,5 L20,5 L20,7 L0,7 L0,5 Z M0,9 L20,9 L20,11 L0,11 L0,9 Z M0,13 L20,13 L20,15 L0,15 L0,13 Z" />
          </svg>
        </p>
      </button> 
      <div className="bg-gray-200 w-px" /> 
      */}
      <button
        type="button"
        onClick={() => onChangeView(1)}
        data-title={t("widok kafelkowy")}
        data-placement="left"
        data-cy="tile-view-button"
        className={`${mode == 1 ? 'bg-primary text-white hover:bg-gray-700' : 'bg-gray-100 text-gray-800 hover:bg-gray-200'} py-2 px-3 
          rounded-l
        `}
      >
        <p className='rounded'>
          <svg className={`w-4 h-4 ${mode == 1 ? 'text-white' : ''}`} viewBox="0 0 16 16" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 
                10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 
                5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"
            />
          </svg>
        </p>
      </button>
      <div className="bg-gray-200 w-px" />
      <button
        type="button"
        onClick={() => onChangeView(0)}
        data-title={t('widok tabelaryczny')}
        data-placement="left"
        data-cy="table-view-button"
        className={`${mode == 0 ? 'bg-primary text-white hover:bg-gray-700' : 'bg-gray-100 text-gray-800 hover:bg-gray-200'} font-bold py-2 px-3 
          rounded-r transition ease-in-out duration-150 outline-none focus:outline-none
        `}
      >
        <p className='rounded'>
          <svg className={`w-4 h-4 ${mode == 0 ? 'text-white' : ''}`} viewBox="0 0 16 16" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 
                1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"
            />
          </svg>
        </p>
      </button>
    </div>
  );
};

ArticleSwitch.propTypes = {
  handleDisplayMode: PropTypes.func.isRequired,
};

export default ArticleSwitch;
