import { useMemo, useState, useRef } from 'react';
import CountryList from './CountryList';

// import flagsImage from '../translate/flag-icons.png';
import '../translate/flag-icons.css';

const SelectCountry = ({
  name,
  defaultValue = "",
  required = false,
  onChange = (value) => {}, 
  className = '',
  register = []
}) => {
  const [selected, setSelected] = useState(defaultValue.toUpperCase()); // key
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const regionNames = new Intl.DisplayNames(['pl' /* lowercase */], { type: 'region' });
  const list = useMemo(() => (new CountryList()).getData(), []);
  const refList = useRef();
  const refSearch = useRef();

  const onShowList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(!isOpen);

    setTimeout(() => {
      refList.current.focus();
    }, 100);
  };

  const onHideList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (refList.current && !refList.current.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 100);
  };

  const onSelect = (e, value) => {
    e.preventDefault();

    setSelected(value);
    onClearFilter(e);
    setIsOpen(false);
    setTimeout(() => onChange(value), 10);

    return;
  };

  const onSearchFilter = (e) => {
    setSearch(""+e.target.value);
    return true;
  };

  const onClearFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSearch('');
    refSearch.current.value = '';
    return;
  };

  const getCountryName = () => {
    if (selected == "")
      return "-------"; 

    return regionNames.of(selected.toUpperCase());
  }

  const filterList = (_country)  => {
    if (search == "") return true;

    // const countryName = ""+_country.text;
    const countryName = regionNames.of(_country.value).toLocaleLowerCase(); // 2 chars [A-Z]
    const countryIso = ""+_country.value.toLowerCase();
    const countrySearch = search.toLowerCase();

    return countryName.includes(countrySearch) || countryIso.includes(countrySearch);
  };

  // useEffect(() => {
  //   setSelected(defaultValue);
  // }, [defaultValue]);

  return (
    <div className={`relative w-full`}>
      <input name={name} type="hidden" value={selected} {...register} />
      <button
        type="button"
        // aria-haspopup="listbox"
        // aria-labelledby="listbox-label"
        className={`relative w-full flex flex-row items-center py-2 pr-9 text-left bg-white border rounded shadow-sm cursor-default  
          sm:text-sm ring-1 ${className} ${isOpen ? 'border-primary ring-primary' : 'border-gray-300 ring-transparent'}`}
        onMouseDown={onShowList}
      >
        <span className="items-start flex-grow w-full pl-3 max-w-max xs:max-w-10rem">
          {getCountryName()}
        </span>
        <span className="flex items-end justify-end flex-grow">
          <i className={`w-6 h-4 fflag ${"fflag-"+selected.toUpperCase()}`} 
            // style={{ backgroundImage: `url(${flagsImage})` }}
            data-lang={selected.toLowerCase()}
          />
        </span>
        <p className={`absolute inset-y-0 right-0 flex items-center pl-2 pr-3 z-10`}>
          {/* <svg className="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 3a1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
          </svg> */}
          <svg className="w-3 h-3 text-gray-500" viewBox="-5 -6 38 38" fill="currentColor">
            <path d="M29.994,10.183L15.363,24.812L0.733,10.184c-0.977-0.978-0.977-2.561,0-3.536c0.977-0.977,2.559-0.976,3.536,0 l11.095,11.093L26.461,6.647c0.977-0.976,2.559-0.976,3.535,0C30.971,7.624,30.971,9.206,29.994,10.183z"/>
          </svg>
        </p>
      </button>
      <div
        tabIndex={-1}
        ref={refList}
        onBlur={onHideList}
        className={`absolute w-full z-20 transition-opacity mt-1 right-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
      >
        <div className="p-2 border-b border-dashed">
          <div className="relative">
            <input
              ref={refSearch}
              className="w-full pr-10 text-sm border-gray-300 rounded"
              type="text"
              placeholder={"filtruj kraj"}
              onKeyUp={onSearchFilter}
              defaultValue={""}
            />
            <button className={`absolute ${search.length == 0 ? '' : 'hidden'} top-3 right-3 z-10`}>
              <svg className="w-4 h-4 text-gray-500" viewBox="0 0 368.167 368.167" style={{ enableBackground: "new 0 0 368.167 368.167" }} fill="currentColor">
                <path d="M248.084,96.684h12c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-12c-4.4,0-8,3.6-8,8C240.084,93.084,243.684,96.684,248.084,96.684z"/>
                <path d="M366.484,25.484c-2.8-5.6-8.4-8.8-14.4-8.8h-336c-6,0-11.6,3.6-14.4,8.8c-2.8,5.6-2,12,1.6,16.8l141.2,177.6v115.6
                  c0,6,3.2,11.2,8.4,14c2.4,1.2,4.8,2,7.6,2c3.2,0,6.4-0.8,9.2-2.8l44.4-30.8c6.4-4.8,10-12,10-19.6v-78.8l140.8-177.2
                  C368.484,37.484,369.284,31.084,366.484,25.484z M209.684,211.884c-0.8,1.2-1.6,2.8-1.6,4.8v81.2c0,2.8-1.2,5.2-3.2,6.8
                  l-44.4,30.8v-118.8c0-2.8-1.2-5.2-3.2-6.4l-90.4-113.6h145.2c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-156c-0.4,0-1.2,0-1.6,0l-38.4-48
                  h336L209.684,211.884z"/>
              </svg>
            </button>
            <button 
              className={`absolute ${search.length > 0 ? '' : 'hidden'} z-10 outline-none`}
              style={{top:"7px", right:"7px"}}
              onMouseDown={onClearFilter}
            >
              <svg className="w-6 h-6 text-gray-500" viewBox="-220 -250 800 800" fill="currentColor">
                <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
              </svg>
            </button>
          </div>
        </div>
        <ul
          role="listbox"
          // aria-labelledby="state-cart-label"
          // aria-activedescendant="state-cart-0"
          className="w-full overflow-auto text-sm scroll-thin rounded-b-md pb max-h-48 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          {search == "" && (
            <li
              key={`prop_selectcountry_choose_default_pl`}
              role="option"
              className={`relative cursor-default select-none hover:bg-gray-200 mb-1 border-b border-double
                ${selected == 'PL' ? 'bg-primary text-white hover:text-primary' : 'bg-gray-50 text-gray-700 '}
              `}
            >
              <button
                type="button"
                onMouseDown={(e) => onSelect(e, 'pl')}
                className="w-full px-3 py-2 outline-none"
              >
                <div className="flex items-start">
                  <span className="block text-sm font-normal text-left truncate">
                    {regionNames.of('PL')}
                  </span>
                </div>
              </button>
            </li>
          )}
          {list.filter(val => filterList(val)).length == 0 && (
            <div className="relative px-2 py-2 text-center text-yellow-700 transition bg-yellow-100 rounded" role="alert">
              <span className="block text-sm sm:inline">Brak krajów dla danego filtra</span>
            </div>
          )}
          {list.length > 0 && list.filter(val => filterList(val)).map(el => (
            <li
              key={`prop_selectcountry_choose_${el.value}`}
              role="option"
              className={`relative cursor-default select-none hover:bg-gray-200 ${selected == el.value ? 'bg-primary text-white hover:text-primary' : 'text-gray-700 '}`}
            >
              <button
                type="button"
                onMouseDown={(e) => onSelect(e, el.value)}
                className="w-full px-3 py-2 outline-none"
              >
                <div className="flex items-start">
                  <span
                    className="block text-sm font-normal text-left truncate"
                  >
                    {regionNames.of(el.value)}
                  </span>
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectCountry;
