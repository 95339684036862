import { useEffect, useRef, useState } from 'react';
import { parseJSON } from '../util';
import api from '../../components/service/api.ts';
import { useTranslation } from 'react-i18next';

// const userAttendant = {
//   firstname: '',
//   lastname: '',
//   mail: '',
//   phone: '',
// };

const getAttendant = async () => {
  const res = await api.getAttendant();
  return res?.data;
};

const getUser = async () => {
  const res = await getAttendant();
  if (res?.status != 0) return false;

  const userData = parseJSON(res.dane);

  if (!Array.isArray(userData) || userData.length == 0)
    return false;

  return [ ...userData ];
};

// const userProfileImage = 'data:image/gif;base64,/R0lGODlhyADIAMIAAP/yAAoKCgAAAcRiAO0cJAAAAAAAAAAAACH5BAEAAAUALAAAAADIAMgAAAP+WLrc/jDKSau9OOvNu/9gKI5kaZ5oqq5s675wLM90bd94ru987//AoHBILBqPyKRyyWw6n9CodEqtWq/YrHbL7Xq/4LB4TC6bz+i0es1uu9/wuHxOr9vv+Lx+z+/7/4CBgoOEhYaHiImKi4yNjo+QkZKTlJWWl5iZmpucnZ6foKGio6SlpqeoqaqrrK2ur7CxsrO0tba3uLm6u7y9vr/AwcLDxMXGx8jJysvMzc7P0NHS09TV1tfY2drb3N3e3+Dh4uPk5eaTAukCzOrry+3s6sjtAfUB8MP09vjC+vX8wfzdk/dLoL2B6YAZ3EfQ18J/DXs9ROjOobqDBwGSmHj+ENJEjSM42vN4ESPEhCdE1iOZzuTJiiVUBmApwCVFEO3aAdjJs+fOjo8+RuSQU53PowCAOhKK0kPRdEh9Km3EFCbRp1F7TmWkEylIC12zZt26KKzPrxXMij1KVpFanmgpvF3Ls22iuQDiTsBL1y6Yp4AD28yI1evQvUbprvX7JbDjnIMZFo2q1wFfxT9HnnnMuWZkingrN7iMmbGXzo8/g058VDQD0opNZ5F5ELNtw00jwL4tGwtte7eDwz1smbVwpL2v/K53PLjo3baTW1keoPnt58at19VsRqZW4NrPEi8AXbj02SUjf2cevifa8sHP+04/eH319sNzv86OP/P+ys302WRffzu9x19/8m2BWkvg9WcgVMepBseCnrHn4Hjw2WfThAvWRuCDAjQn4RsUenihfgtkuF1kgJiIn2xmDSDjAPYx4mJ7MBo3I40rzrTIjeHlCOFOO9b4Y4MvcqebjjMaqYiLoR2YlJIQtLPjlTMmqAeUUuIlpABYYqllHlwOKZ6ZTi6ZTphXjolHmSHiFidbVD5gJZtZ1mnIQQT0ScBtfv7ZI4V3iqlnIXz6CaiigxK6Zphu3pFon4tS2qijbEZqx6SCYhaofY4+auh/jgCpXZE8oSqWpn2Yap2qAMAaFat8uNocrLIid6iNSLaHa5OL7fqIarf9KmNfwpaK+lmxwBLZ7FjJNkKsbcbyuGq0vKpH7bO50klqJ7YSmCYn4Yrrn4+elGsurYeoKy67e/ZqrrfogivvvONu4i6B8CJ6L77nguKigD0O7FK+mhhskoZIEhzwJwpjxLCFUy7co8ANH1xwxhY/LIpdIB/qmr6Hhvztfih+XPLKJ6c4HsYtK2ByvShb9UQCADs=';
// const userProfileImage = 'data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIA0KCSB3aWR0aD0iNDUuNTMycHgiIGhlaWdodD0iNDUuNTMycHgiIHZpZXdCb3g9IjAgMCA0NS41MzIgNDUuNTMyIiBmaWxsPSJjdXJyZW50Q29sb3IiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1LjUzMiA0NS41MzI7Y29sb3I6IzJkMzc0OCI+DQo8Zz4NCgk8cGF0aCBkPSJNMjIuNzY2LDAuMDAxQzEwLjE5NCwwLjAwMSwwLDEwLjE5MywwLDIyLjc2NnMxMC4xOTMsMjIuNzY1LDIyLjc2NiwyMi43NjVjMTIuNTc0LDAsMjIuNzY2LTEwLjE5MiwyMi43NjYtMjIuNzY1DQoJCVMzNS4zNCwwLjAwMSwyMi43NjYsMC4wMDF6IE0yMi43NjYsNi44MDhjNC4xNiwwLDcuNTMxLDMuMzcyLDcuNTMxLDcuNTNjMCw0LjE1OS0zLjM3MSw3LjUzLTcuNTMxLDcuNTMNCgkJYy00LjE1OCwwLTcuNTI5LTMuMzcxLTcuNTI5LTcuNTNDMTUuMjM3LDEwLjE4LDE4LjYwOCw2LjgwOCwyMi43NjYsNi44MDh6IE0yMi43NjEsMzkuNTc5Yy00LjE0OSwwLTcuOTQ5LTEuNTExLTEwLjg4LTQuMDEyDQoJCWMtMC43MTQtMC42MDktMS4xMjYtMS41MDItMS4xMjYtMi40MzljMC00LjIxNywzLjQxMy03LjU5Miw3LjYzMS03LjU5Mmg4Ljc2MmM0LjIxOSwwLDcuNjE5LDMuMzc1LDcuNjE5LDcuNTkyDQoJCWMwLDAuOTM4LTAuNDEsMS44MjktMS4xMjUsMi40MzhDMzAuNzEyLDM4LjA2OCwyNi45MTEsMzkuNTc5LDIyLjc2MSwzOS41Nzl6Ii8+DQo8L2c+DQoNCjwvc3ZnPg0K';

const Guardian = () => {
  const { t } = useTranslation("home");
  const [isOpen, setIsOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const refList = useRef();
  let isMounted = true;

  const onBlurOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (isMounted && refList.current && !refList.current.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 100);
  };

  useEffect(() => {
    getUser().then((_userList) => {
      if (!isMounted || _userList == false) return;
      setUserList(_userList);
    });
   
    return () => {
      isMounted = false;
    }
  }, []);

  if (!isMounted || userList.length == 0 || userList[0].firstname == '') {
    return <></>;
  }

  return (
    <div>
      <div className="relative inline-block text-left">
        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            onBlur={onBlurOpen}
            type="button"
            className="text-primary"
          >
            {t('Opiekun')}&nbsp;
            <svg className="inline-block w-4 h-4 -mt-px" viewBox="0 0 24 24" fill="none">
              {/* <path d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> */}
              <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
        <div
          tabIndex={-1}
          ref={refList}
          className={`origin-top-left absolute left-0 top-6 z-20 transition-opacity ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
          // style={{minWidth: "280px"}}
          onBlur={onBlurOpen}
        >
          <div className="relative p-4 bg-white rounded-md shadow-lg outline-none ring-1 ring-black ring-opacity-5">
            <div className="flex flex-col gap-2" translate="no">
              {/* <div className="w-20">
                <img
                  className="mr-2 text-gray-800"
                  width="64"
                  height="64"
                  style={{ width: "64px", height: "64px"}} 
                  src={`${userProfileImage}`} />
              </div> */}
              {userList.map((user, index) => (
                <div key={`guardian_${index}`} className={`text-black text-sm ${index > 0 && 'border-gray-300 border-t border-dashed pt-2'}`}>
                  <p className="font-bold">{user.firstname}&nbsp;{user.lastname}</p>
                  {(user.mail != '' || user.phone != '') && (
                    <ul className="mt-1">
                      {user.mail != '' && <li><a className="hover:text-primary" href={`mailto:${user.mail}`}>{user.mail}</a></li>}
                      {user.phone != '' && <li><a className="hover:text-primary" href={`callto:${user.phone}`}>{user.phone}</a></li>}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guardian;
