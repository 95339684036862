import { useRef, useCallback, useState, useEffect } from 'react';
import { hashCode, debounce } from '../../components/util';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import React from 'react';

const DivGrow = styled.div`
  display: grid;

  &::after {
    font-size:1rem;
    line-height:1.5rem;
    content: attr(data-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    word-break: break-all;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
  }

  & > textarea,
  &::after {
    grid-area: 1 / 1 / 2 / 2;
  }

  &::after {
    padding: 0.60rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
`;


const TextareaNote = ({
  name,
  className,
  maxLength = 65000,
  defaultValue = "",
  onChange = (data) => {}
}) => {
  const { t } = useTranslation("cart");
  const refNote = useRef();
  const [isSave, setIsSave] = useState(true);

  const onTextareaGrow = useCallback( // for non multi
    debounce(() => {
      isSave == true && setIsSave(false);
      // e.preventDefault();
      const note = refNote.current.value.substring(0, maxLength);
      refNote.current.parentNode.dataset.value = note;
    }, 50),
    []
  );

  const onClearNote = () => {
    setIsSave(false);
    refNote.current.value = "";
  };

  const onSaveNote = () => {
    if (isSave == true || refNote.current == undefined) return;
    setIsSave(true);
    typeof onChange == "function" && onChange(refNote.current.value);
  };

  useEffect(() => {
    onTextareaGrow();
  }, []);

  return (
    <div className="relative flex flex-row w-auto md:max-w-408">
      <div className="flex flex-col flex-grow">
        <DivGrow className="text-sm">
          <textarea
            ref={refNote}
            onInput={() => onTextareaGrow()}
            //onBlur={onBlurSave}
            className={`${className} text-base`}
            name={name}
            maxLength={maxLength}
            defaultValue={defaultValue}
            key={hashCode(defaultValue || "")}
            data-cy="order-comment"
          />
        </DivGrow>
        <span className="text-xs text-gray-400">
          <Trans t={t} i18nKey={"_UpTo"} values={{ max: maxLength }}>
            {"maksymalnie {{max}} znaków"}
          </Trans>
        </span>
      </div>
      <div className="absolute right-0 flex flex-row m-px -top-3"> 
        <button
          type="button"
          onClick={onClearNote}
          className="p-1 mr-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
          data-tooltip={t('wyczyść uwagi')}
        >
          <svg className="w-4 h-4 text-black bg-white rounded-full" viewBox="-1.5 -2.5 24 24" fill="currentColor">
            <path d='M12.728 12.728L8.485 8.485l-5.657 5.657 2.122 2.121a3 3 0 0 0 4.242 0l3.536-3.535zM11.284 17H14a1 1 0 0 1 0 2H3a1 1 0 0 1-.133-1.991l-1.453-1.453a2 2 0 0 1 0-2.828L12.728 1.414a2 2 0 0 1 2.828 0L19.8 5.657a2 2 0 0 1 0 2.828L11.284 17z'/>
          </svg>
        </button>
        <button
          type="button"
          onClick={onSaveNote}
          className="p-1 text-white bg-white border border-b-0 border-l-0 border-r-0 border-gray-300 rounded-full"
          data-tooltip={t('zapisz uwagi')}
        >
          <svg className={`"w-4 h-4 ${isSave == false ? 'bg-green-500' : 'bg-gray-500'} rounded-full`} viewBox="-180 -220 750 750" fill="currentColor">
            <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TextareaNote;
