import { useEffect, useRef } from 'react';

const ButtonCounter = ({ onChange = null, handleReset = -1}) => {
  const refNumber = useRef();

  useEffect(() => {
    if (handleReset == -1) return;

    refNumber.current.value = 1;
    typeof onChange === "function" && onChange(1);
  }, [handleReset]);

  const onNumber = (num) => {
    let numCurr = parseInt(refNumber.current.value, 10);

    if(+numCurr + num <= 0)
      numCurr = 1;
    else
      numCurr = +numCurr + num;

    refNumber.current.value = Math.floor(numCurr);
    typeof onChange === "function" && onChange(Math.floor(refNumber.current.value));
  }

  const onValidate = () => {
    // console.log("ONCHANGE", refNumber.current.value);
    if (parseInt(refNumber.current.value, 10) <= 0)
      refNumber.current.value = 1;

    let numberTmp = String(refNumber.current.value).replace(/[^\d]/g, '').trim();
    numberTmp = Math.floor(+numberTmp || 1);
    numberTmp = +numberTmp <= 1000 ? numberTmp : 1000;
    refNumber.current.value = numberTmp;
    typeof onChange === "function" && onChange(numberTmp);
  }

  return (
    <div className="flex flex-row bg-white border border-gray-300 rounded shadow-sm" title="mnożnik pozycji we wzorcu">
      <button
        type="button"
        className="p-1 m-1 bg-gray-200 rounded w-7 h-7 hover:bg-primary hover:text-white focus:outline-none"
        onClick={() => onNumber(-1)}
        title="wielokrotność pozycji"
      >
        <span className="block text-2xl font-light leading-3 -mt-6px">−</span>
      </button>
      <input
        ref={refNumber}
        className="w-10 px-1 text-sm text-center border-transparent rounded-md h-9"
        type="number"
        step="1"
        min="1"
        defaultValue="1"
        max="1000"
        onChange={onValidate}
      />
      <button
        type="button"
        className="p-1 m-1 bg-gray-200 rounded w-7 h-7 hover:bg-primary hover:text-white focus:outline-none"
        onClick={() => onNumber(1)}
      >
        <span className="block text-2xl font-light leading-3 -mt-6px">+</span>
      </button>
    </div>
  );
}

export default ButtonCounter;
