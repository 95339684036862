import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useCartAction from '../hook/cartaction';
import { IconPattern } from '../icon';

const PatternBuy = () => {
  const history = useHistory();
  const { t } = useTranslation("home");
  const [state] = useCartAction();

  const [productCount, setCartProductCount] = useState(0);
  const getCartProductCount = (_state) => _state.countW;
  let isMounted = false;

  useEffect(() => {
    isMounted = true;
    const productCnt = getCartProductCount(state.cart.summary);
    isMounted && setCartProductCount(productCnt);

    return () => {
      isMounted = false;
    }
  }, [state.summary]);

  const onEnter = () => {
    history.push('/pattern');
  };

  return (
    <button
      type="button"
      className={`${window.location.pathname === '/pattern' ? 'active' : ''} text-gray-800 hover:text-primary py-4 px-2 xs:px-4 flex`}
      onClick={onEnter}
      data-testid="gorna_belka-wzorce_zakupu"
    >
      <span className="text-xs leading-4 text-right mr-1 hidden xs:flex flex-col">
        <span><Trans t={t}>wzorce<br/>zakupu</Trans></span>
      </span>
      <IconPattern size="8" />
      <span className="relative top-0 right-2">
        <span className={`${productCount <= 0 ? 'hidden' : ''} flex justify-center text-center`}>
          <span
            className="rounded-full px-1 text-xs text-white bg-primary font-bold font-sans group-hover:bg-gray-800"
            style={{ minWidth: '17px', height: '17px', paddingTop: '1px' }}
          >
            {productCount}
          </span>
        </span>
      </span>
    </button>
  );
};

export default PatternBuy;
