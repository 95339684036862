import React from 'react';
import AlertDialog from './modal/AlertDialog';


const ErrorBoundaryContext = React.createContext((error) => {});

export const useErrorHandler = () => {
  return React.useContext(ErrorBoundaryContext)
}

export const ErrorFallback = ({error, resetErrorBoundary}) => (
  <AlertDialog title='💡 Błąd ogólny' message={"[text]"} portal={true} onClose={resetErrorBoundary} />
)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error : new Error("") };
    this.showReject = false;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error', error, errorInfo);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  promiseRejectionHandler = (e) => {
    console.warn(`UNHANDLED PROMISE REJECTION: ${e.reason}`);
    this.showReject && this.setState({ hasError: true, error: e?.reason });
    e.preventDefault();
    e.stopPropagation();
  }

  triggerError = (error) => {
    // errorService.log({ error, errorInfo });
    this.setState({ hasError: true, error });
  }

  resetError = () => {
    this.setState({ hasError: false });
  }

  render() {
    return (
      <ErrorBoundaryContext.Provider value={this.triggerError}>
        {this.props.children}
        {this.state.hasError && (
          <AlertDialog 
            title='💡 Błąd ogólny'
            message={this.state.error?.message}
            portal={true}
            onClose={this.resetError} 
        />)}
      </ErrorBoundaryContext.Provider>
    );
  }
}

export default ErrorBoundary;
