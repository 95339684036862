const imgNotNetwork = `data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEPERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/wAALCAEsASwBAREA/8QAHAABAAMBAQEBAQAAAAAAAAAAAAQFBgMCBwEI/8QARBAAAQMDAAUIBwYEBQUBAQAAAQACAwQFEQYSITFREyIyQWGh0eEUQnGBkbHBFSNSZHKjNDVic1NUksLwJGOTovFEgv/aAAgBAQAAPwD+y0RERERERERERERERERERERERERERERERERR6qupKUZqKiNh4E7fgqqp0noY9kMcsx441R3qun0qqnAiGmij7XEu8FEl0hujycTtYCNzWDZ8VxN5uZGDWy9y5faNf/nan/yle47tcoxhtbNjtdn5rtHfrqwYFUT+poP0UuHSiuafvIoJB7CPqp1NpVTuIFRTSR8S06w+itKS72+qwIqlgcfVdzT3qcNoyERERERERERERERERfj3tYwve4NaN5JwAqO4aS0kGWUzTUPHXub8etUFbfLjVZBnMTD6sezv3qtJJOSclERERERFLorlW0eOQqHtaPVO1vwKvbfpS04ZWw4/rj8FoKWqp6qPlKeVsjew7l2RERERERERERERUt20hpqQmKnAnmG/B5rT2nrWVr7hV1z9aomLh1NGxo9yioiIi9iKU7onn/8Akrr6DW/5Oo/8ZXN9PUMdqvglaeBYQuZBaSCCCOooiIi6U881PIJIJHRvHW04WktWk2cRXBuOrlWj5jwWkikjljEkT2vYdoc05BXpERERERERERcaypgpIDNUSBjBx6+wLH3q
+1FaXRQkw0+7A6Tvb4KnREU6htFfWYMVO4MPrv5oVzS6KjYaqqP6Yx9T4K0p7Da4f/zCQ8XklToqaniGI4ImD+lgC6oi8SRRSAiSNjwd4c0HKh1Fnts4OvSRgnrZzT3KtqtFqZ+TTTyRHg4awVRW6P3GmBc2MTtHXGcn4b1VOa5ri1zS1w3gjBC/ERTLZcqq3ya0D+YTzmO6JWztN1prjH92dWUDnRnePEKeiIiIiIiIih3a4wW6nMkpy89BgO1xWHudfUXCoMs7tnqsHRaOxRURW1rsNZWYfIDBD+J42n2Baa3WWgosObEJJB68m0+AVkiIiIiIijV1BSVrcVEDXn8W4j3rN3PRmaIGSieZm/gdsd7uKoJGPjeWSNcxw2EEYIXlF7hlkhlbLE9zHtOQQdoWysF7ZXAQVGrHUDdwf7O3sVyiIiIiIiKFebjFbqUyvw6Q7I2fiPgsJWVM1XUOnneXvd8B2DsXFF3oqSorJxDTxl7jv4AcStdZ7DTUQEs+J5+JHNb7B9VcoiIiIiIiIihXS2UlwjxMzDx0ZG7HBY672mptz8vGvEd0jRs9/BV6L9aS1wc0kEHII3hbDRm8+ltFLVOHLtHNcfXHir1ERERERR7jWQ0NK+omPNbuA3uPALA3Gsmrqp08ziSTsHU0cAo6L9bq6w1s6uduN+Fv7IyhbQMNABybtpPrE9vapyIiIiIiIiIiLzIxkkZjkaHscMEEZBWR0hsTqTNTRtc+D1m7yzyVCi/WOcx4exxa4HIIO0FbfRy6tuFPychxURjnf1DiFbIiIiIvx7msaXOIDQMknqWE0guTrjWZbkQR7Ix9feq1ERTrNc5rbUa7Muid02cfNbqjqYaunbPA8OY7u7CuyIiIiIiIiIiIslpPZRBrVtI37on
MjAOj2jsWeRdaOolpallRCcPYcjwX0C2VkdfRsqItmdjm/hPWFJREREWb0xuOpGKCF3Odtlx1DqCyqKVa6KWvq2wRbM7XO6mjirq/aPiGEVFA1xDG89mck9oWbRTrNc5rbUa7Muid02Z3+a3VHUw1dO2eB4cx3d2FdkREREREREREQgEEEAg7CCsVpNajQz8vA3/p5DsA9Q8FTIrbRm4+g1oZI7EEux3YeorcIiIi4V9Syko5KiTcxuccT1BfPKmaSonfPK7We85JXNfrQXODWgkk4AHWt5o/bm2+iAcBy79sh+nuVisxpLY+lW0TO2SMDvHgswinWa5zW2o12ZdE7pszv81uqOphq6ds8Dw5ju7sK7IiIiIiIiIiIuVXBFVU74Jm6zHjBXz+5UclDWPp5N7TzT+IdRUZFt9Fa81lv5OR2ZYeac7yOo/84K3RERZXTWtLpY6Fh2N57+09QWbRaHQ638rUGulbzIjhna7j7lrURZjSWx9KtomdskYHePBZhFOs1zmttRrsy6J3TZnf5rdUdTDV07Z4HhzHd3YV2RQ6q50lOS10mu8b2sGT4KDJfm5IjpiR1Fz8d2FwbfarIzFCR14B8VIjv0Zd95TOaOLXZ8FYUtfSVJ1Y5RrfhdsPmpKIi5VFTT04++lazZnBO34L9p6iGobrQyteOvB2j28F0REVNpXb/S6Hl425mh2jHW3rH1WKRWFgrTQ3KOQn7t3Mf7D1+5b5EReZZGxRPkecNY0uJ7AvnFZO6pqpah/SkcXezsXJeoY3zSsijGXPcGgdpX0W30zKOjipmYwxuCeJ6yu6IizGktj6VbRM7ZIwO8eCzCKdZrnNbajXZzondNnHzW6o6mGrp2zwPDmO7uwqgu1fVyTPhdrQsBwWDefaev5KvY
1z3tYwZc44A4laOns1Ixg5UOldjaS4ge7C6/ZNv/y//u7xUaus0LonOpQWSAbG5yD8Vn1Z267ywYjnzLH1fiHitDDIyaNskbg5jhkEL0ixk8r55nyyHLnHJXW2SviroXMcRl4ae0E7QtciIiwOkFF6Dc5I2jEb+ez2Hq9yr0W80bq/S7TE5xy9nMd7R5YVkiKn0uqTBaXRtOHTODPdvKxKK90NpOWuDqlw5sI2fqPllbFFHuFZFRw679rjsa0b3FZ6qulZOdkpibnY1mzv3qP6TUf48v8ArKs7PdCxwgqnktPRkcd3Yez/AJ7I2ktj6VbRM7ZIx8x4LMIp1muc1tqNdmXRO6bOPmtdUQ014o21FO8a+Oa7/aVQYlpaoazNWSJwODxG1aeC4UcsYeKiNvEPcGkfFe/TKT/NQf8AkC4VtzpYISWSskkIOq1pzt7cLLovccssYwyV7Rvw1xC9ek1H+PL/AKynpNR/jy/6yuSAkEEHBG4rr6TUf48v+srpDX1kTssqJPY45HwKvLVc21Z5KQBk2/A3O9ngrFFR6ZUnLW9tS0c+E7f0nf8ARY1FodCaksq5aUnmyN1h7R5fJa1EWQ02n17hFADsjZn3n/4FQItxopT8hZ43EYdKS8/TuVsiyt5ndPcJNp1WHUaD1Y396hoiuLLc+T1aapdzNzHn1ew9ny+UbSWx9KtomdskYHePBZhFOs1zmttRrs50Tumzj5rW1ENNeKJtRTvGvjmu4f0lZ6aN8Mjo5Glr2nBBXlERERERF6je6ORsjDhzTkFbGnkE0EcoGA9odjgva8VETZ6eSF4y17S0+9fNpmOilfE7pMcWn2heVKtE/o1zp5s4DZAD7DsPcV9ERF8+vsvLXiqk/wC4W7uGz6KEvUbDJI2NvScQA
vpULGxQsjaMNY0NHuXpFj67+Nn/ALjvmuKIiuLLc+T1aapdzNzHn1ew9ny+UbSWx9KtomdskYHePBZhFOs1zmttRrsy6J3TZx81raiGmvFG2op3jXxzXf7Sqd9trmNLjTPIHDBPco8sUsRAljewndrNIXhERERERa21/wAug/QFJRYXSmHkb1NgYD8PHvG3vyqtF9Ht0vL0EE3442k7Oxd1+Pdqsc49Qyvmkji+RzznLiTtXlT9HouWvNM3B2P1jjs2/Rb9EWPrv42f+475riiIiuLLc+T1aapdzNzHn1ew9ny+UbSWx9KtomdskYHePBZhFOs1zmttRrsy6J3TZx81uqOphq6ds8Dw5ju7sK7EAjBAIPUVWV1np5gXQAQydnRPu6vcqGqglppjFK3VcPgRxC5IiIiItba/5dB+gKSiyunMRE9NNg7Wlp4bDn6rNot1orJylkhznmlzdvtKtFwuTiy31LmnBETiD7l84RXOhzA68gnOWxuI+X1W1RFj67+Nn/uO+a4oiIiu9H66VzxRyAvbjmO/CB1HsUPSWx9KtomdskYHePBZhFOs1zmttRrsy6J3TZx81uqOphq6ds8Dw5ju7sK7KNcKOOsg5N+xw2sd1tKys0b4ZXRSDDmnBC8IiIiLW2v+XQfoCkos9pw0Ghp37ciXA94Pgski2WhTnG1PBOQ2UgdmwK8UK+kts9WQcHkivnyK/wBCGtNylcRtbFs+IWvRFj67+Nn/ALjvmuKIiItDo5ThlKagga0hIB4Aef0VqsxpLY+lW0TO2SMDvHgswinWa5zW2o12ZdE7ps4+a3VHUw1dO2eB4cx3d2FdlR6TU4Do6poxrcx3t6vr8AqVERERa21/y6D9AUlFSaZsa60BxG1soI71jEWt0GJ9DqG52
CQED3LQqHe2l9oqmjGeSd8l89RX2hLw25yswcuiOPcQtgiLH138bP8A3HfNcURERbKljMVLFE7GWMDTjiAuiLMaS2PpVtEztkjA7x4LMIp1muc1tqNdmXRO6bOPmt1R1MNXTtngeHMd3dhXG8sEltmHAaw9xysqiIiItba/5dB+gKSio9NHhtpa0g5fKAPgSsai1ug7SKKof1GTA9w81oVwuDDJQVDG73ROA+C+cIrjQ9+remtxnXY4ezr+i2yIsfXfxs/9x3zXFEREW1Y5r2hzSC0jII6wv1EWY0lsfSraJnbJGPmPBZhFOs1zmttRrsy6J3TZx81tRLFcra91NICJGEAnqPArLTRvhkdHI0te04IK8oiIi1tr/l0H6ApKLPacvxRU8eN8hOfYPNZJFs9C2ObaXOOMOlJHwA+iu1+OAc0tO4jBXzWdhjmfGRgtcWkcMFeFNsUvI3elf1coAduN+z6r6CiLH138bP8A3HfNcURERaqzTCa3REYywahGd2P+BTERFmNJbH0q2iZ2yRgd48FmEU6zXOa21GuzLondNnHzWuqIaa8UTaineNfHMd/tKzs0b4ZHRyNLXtOCCvKIiLW2v+XQfoCkosnpxKHVdPCD0WFx28T5LOot3ovHyVkgyCC7LviVZosBpDDyN5qWYwC/WGzjtUBfrXFrg5pwQcgr6TTStnp45m7ntDh7wuiLH138bP8A3HfNcURERWVhrBT1BikOI5Os9R6lpEREWY0lsfSraJnbJGPmPBZhFOs1zmttRrsy6J3TZnf5rW1ENNeKNtRTvGvjmu/2lZ6aN8Mjo5Glr2nBBXlERa21/wAug/QFJRYPSWfl7zOQctYdQe7f35VagBJwBkr6RRRCCjhhHqMDe5dkWT03p9WrhqQNj2ap9
o/+9yzqLa6IVImtIiJ50Li0+zeFcosfXfxs/wDcd81xREREV5abs3VbBVnBGxsh+virpERfj3NY0ue4NaN5JwAszpLY+lW0TO2SMfMeCzCKdZrnNbajXZl0TumzO/zWuqIaa8UbaineNfHNd/tKzs0b4ZHRyNLXtOCCvKItba/5dB+gKSuVbO2mpJZ3bo2ly+bvcXvc9xyXHJX4ptjg9Ju1PFjI1w4+wbfovoKIqrSqm9ItEhaOdEeUHu39ywyK50Rq/R7nyLjzJxq+/q8Petqix9eCK6cH/Ed81xRERERSqO4VVKA2OTLB6jto8vcrOG/MI++gcDxYc5+K6/btJ/hz/wCkeK4T37eIaf2F5+g8VV1dZUVR++lLgNzRsA9ysbLc+T1aapd93uY8+r2Hs+XyjaS2PpVtEztkjA7x4LMIp1muc1tqNdmXRO6bM7/Na2ohprxRNqKd418c13+0rPTRvhkdHI0te04IK8otdbARb6fP+GPkpCz+mtXydJHSNPOlOs79I8/ksii0mhFNmWarcNjRqN9p2n6fFapEX49oewtcMgjBC+dXKmdR10tO7PMdgE9Y6j8FHX6xzmPD2nDmnIK+h2msbXUEVQMZIw8cHDepSo9IaF5eayIFwIxIBvGOv2KlRERERERERXFlufJ6tNUu5m5jz6vYez5fKNpLY+lW0TO2SMDvHgswinWa5zW2o12c6J3TZx81rqiGmvFG2op3jXxzXf7Ss7NG+GR0cjS17TggqVaqJ9ZOMgiJpy52N/Z7Vql+OIa0ucQABkkr59eqw11xln9XOqz9I3KGi+gWKk9DtcMLhh5Gs/2n/mFOREWa01ossjr2Do8yT2dRWWRXeiVwFLWGnldiKY4yep3V4LZoq+ptFHM4uDXRE/g2D4KJ9g
fm/wBvzT7A/N/t+afYH5v9vzT7A/N/t+afYH5v9vzT7A/N/t+afYH5v9vzT7A/N/t+afYH5v8Ab80+wPzf7fmn2B+b/b80+wPzf7fmn2B+b/b80+wPzf7fmrSggfTU4hfMZcHmkjGBwWf0lsfSraJnbJGPmPBZhFOs1zmttRrsy6J3TZnf5rZCOgu0MdSAJG9RBwfYVMjYyNgZG0MaNwAwF6VBphcBDS+hRu+8lHPwdzfNZBFaaMUXplzaXDMUXPd9B8VukREXOphZUU8kEgyx7S0r55X00lHVyU8vSYcZ4jqK4ItvoxcxXUnIyu/6iIYd/UOoq3RERERERERERFmNJbH0q2iZ2yRgd48FmEU6zXOa21GuzLondNnHzW6o6mGrp2zwPDmO7uwrzcauKhpH1Ep2NGwcT1BfPqyolqql9RMcvecns7FyRbzRyg9AtzWvH3snOf2cArJEREVFpbbfSaYVcLcyxDnADa5vksci7UdTLSVLKiF2HsOzgexb6110NwpRNCdu57etp4KUiIiIiIiIiIiLMaS2PpVtEztkjHzHgswinWa5zW2o12ZdE7ps4+a6X+6OuVSC3WbAzoNO/wBpVaivNE7b6VVelStzDEdmfWd5LZIiIiIdowVidJrX6DU8tC0+jyHZ/SeHgqdFLtVfNbqoTRbQdj2nc4Ld2+sgrqds8Dsg7x1tPAqQiIiIiIiIiIiLMaS2PpVtEztkjHzHgswiIpNto5a+rZTxDf0ndTRxX0Cjp4qSmZTwtwxgwO3tXVERERFyqoIqqnfBM3WY8YIWDvFumt1UY3gmMnMb/wAQ8VCRSbbXT0FSJoHfqadzhwK29pulNcYsxHVkHSjO8eIU5ERERERERERFyq6iGlgdNPIGMHWVgLrPT1NdJNTQcjG7
1eJ49iiovdPDLUTNhhYXvccABbux22O3UoYADM8AyO4nh7FYIiIiIiKNcaOGupXQTDYdzhvaeIWEudBUW+oMU7dh6LxucFFRe4ZZIZWyxPcx7TkEHaFqbPpJHJiGvxG/cJR0T7eC0LXNc0OaQ4HaCDvX6iIiIiIiIiqrvfKWhBjYRNP+Bp2D2lY+4V1TXzcpUSZxuaNgb7AoyL3DFJNK2KJhe9xwGjrW20etDbdDykmHVDxzj+EcArVEREREREXCvpIK2mdBO3Wadx6weIWIvNpqLdKdYF8JPNkA2ew8Cq9EU223SsoHfcyZZ1xu2tPgtNb9JKOfDagGnfxO1vxVzG9kjQ9jmuadxByF6REREREVbcL3QUeWmXlZB6ke0+CzVzv9ZWAxxn0eI9TDtPtKqERdqKlnrJxDTxl7j8AOJW2slphtsZIPKTOHOeR3DsVkiIiIiIiIi8yxxyxujlY17HDBaRkFZW9aOSRF09AC+PeYvWHs4rOuBaSHAgjYQURF2pauppXa1PO+M/0nYfcril0nrY9k8ccw49E/89ys6fSegfsljmi92R3KdFebZIMisjH6ub81IFZSHGKqA5/7gXXXZ+NvxXh9TTMdqvqImngXgLhLdLdFnXrIcjeA7J7lCqNJLbGDqOklI6mtx81W1WlUpyKamYzg55yfgFUVtzrqvInqHlv4RsHwChoiK0s9lqrgQ8jkoOt7hv8AYOtbK30VNQwiKnjDR1nrd7SpCIiIiIiIiIiKuutno7gC57eTm6pG7/fxWUudkrqLLizlYh67NvxHUq1EREREREREUqgt9XXP1aeFzh1uOxo9609q0cpqbEtURPKNuPUHu61egADAGAiIiIiIiIiIiIiKsuFjoKwlxj5KQ+vHs7tyoK3Rqthy6ncyob
wGx3wKp54JoHas0T4zwc0hc0RERERfrGue4NY0ucdwAyVZ0VguNThxiELD1ybO7er636NUcGHVBNQ/gdjfgruNjI2BjGhrRuAGAF+oiIiIiIiIiIiIiIi8yRxyNLJGNe09ThkKuqbDbJ9vo/JnjGceSrp9FISPuat7Twe0H5YUSXRasBPJzwvGNmcglcTo3cwM6sR7A9cfsC7f5X/3b4rpHo5dHNyY42djnj6LrHovcHDLpIGdhcT9FLh0UGfvqzZwazzU+m0ctsRBe2SYj8btnwCs6alp6ZurBBHGP6W4XVERERERERERERERERERERERERERERERERERf//Z`;

export default imgNotNetwork;