import axios, { Canceler } from 'axios';
import storageSession from '../util/storage';
import { history } from '../router/index';

import AlertDialog from '../../components/modal/AlertDialog';
import api from './api';
import { parseJSON } from '../util';

// interface AxiosInstanceWithCancel extends AxiosInstance {
//   postWithCancel<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
// }
// interface AxiosStaticWithCancel extends AxiosStatic {
//   create(config?: AxiosRequestConfig): AxiosInstanceWithCancel;
// }

const emptyCache = function () {
  if (!('caches' in window)) {
    /* @ts-ignore */
    setTimeout(() => window.location.reload(true), 100);
    return false;
  }

  caches.keys().then((names) => {
    names.forEach(name => {
      caches.delete(name);
    });
    
    /* @ts-ignore */
    setTimeout(() => window.location.reload(true), 100);
  });

  return true;
}

interface ToastBag {
  type: number,
  data: number
}

const axiosI = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : window.location.origin + "/api",
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Max-Age': 600,
    'Content-type': 'application/json',
    'AuthToken' : storageSession.get('token') || '',
  }
});

axiosI.defaults.timeout = 1000 * 62; //default timeout 62s

export let pendingCount = 0;
const toastBag:ToastBag[] = [];

const checkToastBag = (type: number, elapsedTime?: number) => {
  elapsedTime = elapsedTime || 10000;
  const dateNow:number = +(new Date());
  const bagIndex = toastBag.findIndex(el => el.type == type);

  if (bagIndex == -1) {
    toastBag.push({type, data: dateNow});
    return false;
  }

  if ((dateNow - toastBag[bagIndex].data) > elapsedTime) {
    toastBag[bagIndex].data = dateNow;
    return false;
  }

  return true;
};

axiosI.interceptors.request.use(config => {
  const originalRequest = config;
  pendingCount += 1;
  // config.baseURL = 'http://localhost:8080/api';

  return originalRequest;
}, (err) => Promise.reject(err));

axiosI.interceptors.response.use((res) => {
  pendingCount -= 1;
  // const contentType = res.headers['content-type'];
  
  const buildTime = "" + res.headers['build-time'];
  let storeBuildTime = storageSession.get('build-time');

  if (storeBuildTime == undefined) {
    storageSession.set("build-time", buildTime);
    storeBuildTime = buildTime;
  }

  if (buildTime != storeBuildTime && window.location.pathname != "/login") {
    storageSession.set("build-time", buildTime);

    AlertDialog({
      key: "new_version_server",
      title: '💡 Nowa wersja B2B', 
      message: 'Nastąpi przeładowanie strony za 5 sekund',
      color: 'green',
      autoClose : 5000,
      onClose: () => {
        emptyCache(); // refresh page
      },
    });
    return res;
  }


  if (200 === res.status && res?.data?.status == 1) {
    AlertDialog({
      key: "",
      title: '🔥 Api - Wystąpił wyjątek',
      message: res?.data?.info,
      color: "yellow",
      // autoClose : 5000,
    });
    return res;
  }

  if (200 === res.status && res?.data?.status == 2) {
    AlertDialog({
      key: "",
      title: '🍀 Api - Informacja',
      message: res?.data?.info,
      color: "blue",
      // autoClose : 5000,
    });
    return res;
  }

  if (200 === res.status && res?.data?.status == 3) {
    AlertDialog({
      key: "",
      title: '⚠️ Api - Ostrzeżenie',
      message: res?.data?.info,
      color: "yellow",
      // autoClose : 5000,
    });
    return res;
  }

  return res;
}, (err) => {
  const { response } = err;
  console.log('HTTP error', err, "code", err.code, "message", err.message, 'status', response?.status);

  pendingCount -= 1;

  if (axios.isCancel(err)) {
    return new Promise((_, reject) => {
      console.log("HTTP: Cancel by user");

      // if (err.message != "")
      //   AlertDialog({
      //       title: '✍️ Żądanie anulowane', 
      //       message: err.message,
      //       color: 'yellow',
      //   });

      reject();
    });
  }

  if (response?.status == undefined) {
    AlertDialog({
      key: 'not_response_server',
      title: '🚀 Brak odpowiedzi',
      color: 'yellow',
      message: 'Przekroczony czas odpowiedzi. Spróbuj ponownie.',
      // onClose: () => {
      //   // history.push('/login');
      // },
    });

    return;
  }

  if (503 === response.status) {
    AlertDialog({
      key: 'maintenance_server',
      title: '🛠️ Przerwa techniczna',
      message: response?.data?.info || "...",
      html: true,
      color: "indigo",
      onClose: () => {
        if (document.location.pathname != "/login" && response?.config.url != "/wyloguj")
          history.push('/login');
      },
    });

    return Promise.reject(err);
  }
  
  if (500 === response.status || 502 === response.status) {
    // console.log("RESPONSE", response);
    const message = 502 === response.status ? 'Serwer nieosiągalny' : response?.data?.info || ""

    AlertDialog({
      key: 'error_server',
      title: '🧨 Błąd po stronie serwera', 
      message,
      onClose: () => {
        // history.push('/login');
      },
    });

    return Promise.reject(err);
  } 
  
  if (401 === response.status) {
    const title = "🚪 Brak autoryzacji";
    const message = response?.data?.info || "";
    const color = response?.data.status == 2 ? "blue" : "yellow";

    if (response?.data.status == 3 && storageSession.get('remember') && document.location.pathname != "/login") {
      AlertDialog({
        key: 'logon_again',
        title,
        message: 'Nastąpi próba ponownego zalogowania za 5 sekund. ' + message,
        color: "pink",
        autoClose : 5000,
        onClose: async () => {
          const res = await api.postLoginSession()
          if (!res || res?.status != 200 || res.data?.status !== 0) {
            history.push('/login');
            return;
          }

          if (document.location.pathname == "/home") {
            setTimeout(() => window.location.reload(true), 100);
            return;
          }

          history.push('/home');
        },
      });

      return Promise.reject(err);
    }

    if (document.location.pathname != "/login" && response?.config.url != "/wyloguj") {
      AlertDialog({
        key: 'logon_redirect',
        title,
        message: 'Nastąpi przekierowanie do strony logowania za 5 sekund. ' + message,
        color,
        autoClose : 5000,
        onClose: () => {
          history.push('/login');
        },
      });

      return;
    }

    // logon info
    AlertDialog({ key: 'logon_info', title, message, color });
    // return Promise.reject(err);
  }

  return Promise.reject(err);
});

axiosI.defaults.withCredentials = true;

axiosI['updateToken'] = (token) => {
  axiosI.defaults.headers['AuthToken'] = token;
};

interface PromiseWithCancel<T> extends Promise<T> {
    cancel() :void
};

axiosI["postWithCancel"] = function(url, data, config) {
  let cancel:Canceler = (message?:string) => {};
  const cancelToken = new axios.CancelToken(c => cancel = c);
  const promise = this.post(url, data, { ...config, cancelToken });

  (promise as any).cancel = cancel;
  return promise as PromiseWithCancel<any>;
};

export default axiosI;
