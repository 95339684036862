import { useState, useRef, useEffect, useMemo } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import useCartAction from '../hook/cartaction';
import { IconCart } from '../icon';
import ConfirmDialog from '../modal/index';
import { fireWait } from '../util/index';

import './style.css';
import { useTranslation, Trans } from 'react-i18next';

const CartSelect = ({ name = 'Koszyk', typ = 'Z', styleSize = 'small' }) => {
  const { t } = useTranslation("shop");
  const [cartState, cartAction] = useCartAction();
  const [show, setShow] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const [, setConfirmClear] = useState(0);
  const refCartInput = useRef();
  const refList = useRef();

  const onGetCartName = () => {
    const select = typ === 'Z' ? cartState.cart.selectNormal : cartState.cart.selectPattern;
    const cartName = cartAction.getCartName(select, typ);
    console.log('cartselect', 'name', cartName, typ);
    return cartName;
  };

  const refreshCart = useMemo(() => fireWait(async () => {
    const cart = cartState.cart.findAsTyp(typ);
    console.log('refreshCart', cart);

    if (cart === undefined) {
      if (typ == 'Z') {
        const name = typ == 'Z' ? t('Nowy koszyk') : t('Nowy wzorzec');
        const cartId = await cartAction.newCart(name, typ);
        // console.log('cartselect', 'new cart', cartId, typ);
      }

      if (typ == 'W') {
        setShow(false);
      }
    }

    if (refCartInput.current) {
      refCartInput.current.value = onGetCartName();
      // console.log('cartselect change name');
    }

    // console.log('cartselect end effect');
  }), []);

  const onShowList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(!isOpen);
    showInput === true && setShowInput(false);

    setTimeout(() => {
      refList.current && refList.current.focus();
    }, 100);
  };

  const onHideList = () => {
    setIsOpen(false);
    showInput === true && setShowInput(false);
  };

  const onShowEdit = (e) => {
    // console.log('onShowEdit');

    e.preventDefault();
    setShowInput(true);
    setIsOpen(false);
  };

  const onEditDiscard = () => {
    // console.log('click discard');
    setShowInput(false);
    refCartInput.current.value = onGetCartName();
  };

  const onEditSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const select = typ === 'Z' ? cartState.cart.selectNormal : cartState.cart.selectPattern;
    console.log('cartselect onEditSave', refCartInput.current.value, select);
    cartAction.setCart(`${refCartInput.current.value}`, select, typ);

    setShowInput(false);
  };

  const onBlurSaveOrDiscard = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onCartKey = (e) => {
    if (e.key === 'Enter') {
      onEditSave(e);
      e.target.blur();
    }

    if (e.key === 'Escape') {
      onEditDiscard(e);
      e.target.blur();
    }
  };

  const onCartSelect = (id) => {
    // console.log('cart select');
    setIsOpen(false);

    cartAction.selectCart(id, typ);
    refCartInput.current.value = cartAction.getCartName(id);
  };

  const onCartNew = (e) => {
    e.stopPropagation();
    setIsOpen(false);

    if (typ !== 'Z') return;
    const cartCount = 1 + cartState.cart.filterAsTyp("Z").length;
    const name = t('Nowy koszyk') + cartCount;
    const id = cartAction.newCart(name);

    // console.log('onCartNew', id);
    if (id !== false && id !== undefined) {
      refCartInput.current.value = name;
      refCartInput.current.focus();
    }
  };

  const openModal = (id) => {
    const container = document.getElementById('show-modal');

    const closeModal = () => {
      setConfirmClear(0);
      unmountComponentAtNode(container);
    };

    render(
      <ConfirmDialog
        title={typ === 'Z' ? t("Usunięcie koszyka") : t("Usunięcie wzorca")}
        open={true}
        onClose={closeModal}
        onConfirm={() => cartDelete(id)}
      >
        <Trans t={t} i18nKey="_DeleteCart" values={{ name: ""+cartAction.getCartName(id) }}>
          Czy na pewno usunąć koszyk <b>{{name}}</b> z pozycjami?
        </Trans>
      </ConfirmDialog>,
      container,
    );
  };

  const cartDelete = (cartId) => {
    // console.log('DEL before', cartState.cart.toString());
    cartAction.delCart(cartId, typ);
    // console.log("DEL after", cartState.cart);

    const select = typ === 'Z' ? cartState.cart.selectNormal : cartState.cart.selectPattern;
    // if (select === cartId) {
    //   cartAction.selectCartFirst(typ);
    // }
  };

  const onCartDelete = (e, id) => {
    // console.log('onCartDelete');
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);

    if (!cartAction.isCartEmpty(id)) {
      openModal(id);
    } else {
      cartDelete(id);
    }
  };

  useEffect(() => {
    // if (name == '') {
    //   name = '[koszyk]';
    // }
    refreshCart();
  }, [cartState.cart, cartState.select]);

  return (
    <>
      <div className={`${show ? '' : 'hidden'} relative z-20 max-w-sm`}>
        <button
          type="button"
          aria-haspopup="listbox"
          aria-labelledby="listbox-label"
          className="relative w-full pr-10 text-left bg-white border border-gray-300 rounded shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
        >
          <span className="relative z-0 flex items-center w-full">
            <span
              data-title={typ == "Z" ? cartState.cart.selectNormal : cartState.cart.selectPattern}
              data-placement="bottom"
              className={`${styleSize !== 'small' ? 'text-xs font-medium' : 'text-sm font-normal'} uppercase 
                border-gray-300 bg-gray-100 rounded-bl rounded-tl border-0 border-r px-3 py-2`}
            >
              {name === 'Koszyk' && styleSize !== 'small' && (
                <IconCart />
              )}
              {(name !== 'Koszyk' || styleSize === 'small') && (
                t(name)
              )}
            </span>
            <input
              ref={refCartInput}
              type="text"
              defaultValue={onGetCartName}
              className={`${styleSize !== 'small' ? 'text-xs font-medium' : 'text-sm font-normal'} max-w-7rem cartselect-input bg-transparent border-0 focus:ring-0`}
              onFocus={onShowEdit}
              onKeyUp={onCartKey}
              data-testid="wzorzec_input_zmiana_nazwy"
              maxLength={50}
            />
          </span>
          <a
            href={void(0)}
            onMouseDown={onShowList}
            className={`${showInput === true ? '' : 'border-dashed border-l border-gray-300'} absolute inset-y-0 right-0 flex items-center px-2 z-10 cursor-pointer`}
          >
            <svg className="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" clipRule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" />
            </svg>
          </a>
          <span className={`${showInput === true ? '' : 'hidden'} absolute inset-y-0 right-0 flex items-center px-2 z-20`}>
            <a href={void(0)} onBlur={onBlurSaveOrDiscard} onClick={onEditDiscard} className="block w-6 h-6 mr-1 text-white border-4 border-transparent border-opacity-0" data-testid="wzorzec_anuluj_zmiana_nazwy">
              <svg className="w-4 h-4 bg-red-500 rounded-full" viewBox="-220 -220 800 800" fill="currentColor">
                <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
              </svg>
            </a>
            <a href={void(0)} onBlur={onBlurSaveOrDiscard} onClick={onEditSave} className="block w-6 h-6 text-white border-4 border-transparent border-opacity-0" style={{ marginRight: '-3px' }} data-testid="wzorzec_potwierdz_zmiana_nazwy">
              <svg className="w-4 h-4 bg-green-500 rounded-full" viewBox="-180 -220 750 750" fill="currentColor">
                <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
              </svg>
            </a>
          </span>
        </button>
        <div
          tabIndex={-1}
          ref={refList}
          onBlur={onHideList}
          className={`absolute transition-opacity mt-1 right-0 rounded bg-white shadow-lg outline-none ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'} ${styleSize !== 'small' ? 'cartselect-list-tiny' : 'cartselect-list-small'}`}
        >
          <ul
            role="listbox"
            aria-labelledby="state-cart-label"
            aria-activedescendant="state-cart-0"
            className="py-1 overflow-auto text-sm rounded-md max-h-57 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {cartState.cart.entries().map(([, val]) =>
              (val.typ === typ) && (
                <li key={`cart_choose_${val.id}`} id="state-cart-0" role="option" aria-selected="false" className="relative text-gray-700 cursor-default select-none hover:text-white hover:bg-primary">
                  <button
                    type="button"
                    onMouseDown={() => onCartSelect(val.id)}
                    className="w-full outline-none py-2 pl-3 pr-6"
                  >
                    <div className="flex items-center content-center">
                      {/* <img className="flex-shrink-0 w-5 h-5 rounded-full" src="" alt="" /> */}
                      <span className="block -mt-px text-sm font-normal truncate">
                        {val.name || "[koszyk]"}
                      </span>
                    </div>
                    <span className={`${(typ === 'Z' && cartState.cart.selectNormal === val.id) || (typ === 'W' && cartState.cart.selectPattern === val.id) ? '' : 'hidden'} absolute inset-y-0 right-0 flex items-center pr-3`}>
                      <svg className="w-3 h-3" viewBox="4 1 20 20" fill="currentColor" aria-hidden="true">
                        <path clipRule="evenodd" fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                      </svg>
                    </span>
                    <a 
                      href={void(0)}
                      onMouseDown={(e) => onCartDelete(e, val.id)}
                      className={`absolute inset-y-0 right-0 flex items-center pr-3 text-red-500`}
                      data-title-id={val.id}
                    >
                      {/* ICON TRASH */}
                      <span className="p-px bg-white rounded-full">
                        <svg className="w-4 h-4 text-red-600 bg-white border border-white rounded-full" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5 15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z" />
                          <path d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z" />
                        </svg>
                      </span>
                    </a>
                  </button>
                </li>
              ))}
            {(typ === 'Z') && (
              <li id="state-cart-0" role="option" aria-selected="false" className="relative border-t cursor-default select-none text-primary hover:text-white hover:bg-primary">
                <button
                  type="button"
                  onMouseDown={onCartNew}
                  className="w-full outline-none py-2 pl-3 pr-6 "
                >
                  <div className="flex items-center content-center">
                    <span className="block -mt-px text-sm font-normal truncate">
                      {typ === 'Z' ? t('Nowy koszyk') : t('Nowy wzorzec')}
                    </span>
                  </div>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2.5">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 32 32">
                      <path d="M15.5 29.5c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zM21.938 15.938c0-0.552-0.448-1-1-1h-4v-4c0-0.552-0.447-1-1-1h-1c-0.553 0-1 0.448-1 
                        1v4h-4c-0.553 0-1 0.448-1 1v1c0 0.553 0.447 1 1 1h4v4c0 0.553 0.447 1 1 1h1c0.553 0 1-0.447 1-1v-4h4c0.552 0 1-0.447 1-1v-1z"
                      />
                    </svg>
                  </span>
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CartSelect;
