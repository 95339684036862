import { useEffect, useRef, memo } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, elementId = 'portal-root' }) => {
  const el = useRef(null);

  if (!el.current) el.current = document.createElement('div');

  useEffect(() => {
    const mount = document.getElementById(elementId);
    const { current } = el;

    mount.appendChild(current);

    return () => {
      if (mount != undefined && current != undefined && mount.contains(current))
        mount.removeChild(current);
    }
  }, []);

  return createPortal(children, el.current);
};

export default memo(Portal);
