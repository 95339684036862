

const SSummary = ({content = ''}) => {
  return (
    <>
      {content}
    </>
  );
};

export default SSummary;
