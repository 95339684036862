import { useState, useEffect } from 'react';

const dataRibbon = {
  nowosci:0, 
  polecane:0, 
  promocje:0, 
  wyprzedaz:0 
};

const RibbonButton = ({ handleUpdate, defaultValue = ({ ...dataRibbon }), clear = 4 }) => {
  const [ribbon, setRibbon] = useState(defaultValue);
  const [expand, setExpand] = useState(true);

  const changeState = (num) => {
    console.log("RIBBON", ribbon);
    const ribbonTmp = Object.assign({}, ribbon);
    ribbonTmp[num] = 1 - ribbonTmp[num];
    setRibbon(ribbonTmp);
    typeof handleUpdate === "function" && handleUpdate(ribbonTmp);
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    if (clear === 0)
      setRibbon({ ...dataRibbon });
  }, [clear]);

  return (
    <>
      <button
        type="button"
        className="w-full flex justify-between items-center py-3 px-4 pr-6 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-gray-700 focus:outline-none"
        onClick={handleExpand}
      >
        <span className="flex items-center">
          <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <polygon points="494.933,68.267 375.467,187.733 256,68.267 136.533,187.733 17.067,68.267 0,68.267 0,443.733 512,443.733 512,68.267" />
          </svg>
          <span className="mx-4 font-medium">Wyróżnione</span>
        </span>
        <span className="mr-1">
          <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={`${!expand ? '' : 'hidden'}`} d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path className={`${expand ? '' : 'hidden'}`} d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </button>
      <nav className={`${expand ? '' : 'hidden'} px-4 py-2 text-sm`}>
        <button
          type="button"
          onClick={() => changeState('promocje')}
          className={`${ribbon?.promocje === 1 ? 'bg-red-200 text-red-700' : 'bg-gray-200 test-gray-700'} mr-2 mb-2 relative -top-px text-xs inline-flex items-center leading-5 uppercase px-3 rounded-full`}
        >
          Promocja
        </button>
        <button
          type="button"
          onClick={() => changeState('nowosci')}
          className={`${ribbon?.nowosci === 1 ? ' bg-blue-200 text-blue-700' : 'bg-gray-200 test-gray-700'} mr-2 mb-2 relative -top-px text-xs inline-flex items-center leading-5 uppercase px-3 rounded-full`}
        >
          Nowość
        </button>
        <button
          type="button"
          onClick={() => changeState('wyprzedaz')}
          className={`${ribbon?.wyprzedaz === 1 ? 'bg-green-200 text-green-700' : 'bg-gray-200 test-gray-700'} mr-2 mb-2 relative -top-px text-xs inline-flex items-center leading-5 uppercase px-3 rounded-full`}
        >
          Wyprzedaż
        </button>
        <button
          type="button"
          onClick={() => changeState('polecane')}
          className={`${ribbon?.polecane === 1 ? 'bg-yellow-200 text-yellow-700' : 'bg-gray-200 test-gray-700'} mr-2 mb-2 relative -top-px text-xs inline-flex items-center leading-5 uppercase px-3 rounded-full`}
        >
          Polecane
        </button>
      </nav>
    </>
  );
};

export default RibbonButton;
