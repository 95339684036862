import './style.css';

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <a
        className="px-2 text-center"
        href="https://humansoft.pl"
        target="_blank"
        rel="noopener noreferrer"
        translate='no'
      >
        Humansoft sp.&nbsp;z&nbsp;o.o. {year}. All rights reserved. Powered&nbsp;by&nbsp;Humansoft B2B
      </a>
    </footer>
  );
}
