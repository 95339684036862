import { Router, Route, Switch, Redirect } from 'react-router';
import { useContext } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';

import CatalogNav from '../../catalog/components/CatalogHeader';

import ScrollArrow from '../../components/scrollarrow';

const RouteMain = (props) => (
  <>
    <Header />
    <div className='container flex flex-row flex-grow content-start items-stretch justify-start'>
      <Route {...props} />
    </div>
    <Footer />
    <ScrollArrow />
    <div id="show-article" />
    <div id="show-modal" />
  </>
);

export default RouteMain;