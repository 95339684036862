import { useState, useMemo } from 'react';

const useSortData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);
  const [sortChange, setSortChange] = useState('');

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];   

    if (sortConfig == null)
      return sortableItems;

    if (sortConfig.func > 0) {
      return sortableItems;
    }

    sortableItems.sort((a, b) => {
      // char, numeric, date,
      if (sortConfig.type == 'numeric') {
        if (+a[sortConfig.key] > +b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }

        if (+b[sortConfig.key] > +a[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }

        return 0;
      }

      if (String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]))) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }

      if (String(b[sortConfig.key]).localeCompare(String(a[sortConfig.key]))) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }

      return 0;
    });

    return sortableItems;
  }, [items, sortChange]);

  const requestSort = (key, type = undefined, sort = null, func = null) => {
    let direction = 'asc';

    if (sort == null) { // is swap for table header
      if (sortConfig != null && sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
      }

      if (sortConfig != null && sortConfig.key === key && sortConfig.direction === 'desc') {
        key = '';
        direction = '';
      }
    } else {
      direction = sort;
    }

    if (key == '') direction = '';

    if (typeof func === 'function') {
      setSortConfig({ key, type, direction, func: 1 });
      setTimeout(() => func(key, direction), 0);
    } else {
      setSortConfig({ key, type, direction, func: 0 });
      setSortChange(new Date().toString());
    }
  };

  const directionKey = (key) => {
    if (sortConfig && sortConfig?.key === key) return sortConfig.direction;

    return '';
  };

  return { sortedItems, requestSort, directionKey };
};

export default useSortData;
