import { useEffect, useState } from 'react';
import { pendingCount } from '../service/http.ts';

const useTransCustom = (time = 3000) => {
  const [isPending, setPending] = useState(false);
  let interval;
  let timeout;

  const startTransCustom = (func) => {
    if (typeof func !== 'function') return;

    let pendingStart = false;
    setPending(true);

    timeout = setTimeout(() => {
      setPending(false);
    }, time);

    func();

    interval = setInterval(() => {
      if (pendingCount > 0) pendingStart = true;

      if (pendingStart && pendingCount <= 0) {
        setPending(false);
        clearTimeout(timeout);
        clearInterval(interval);
      }
    }, 200);

    // clearTimeout(timeout);
    // setPending(false);
  };

  useEffect(() => () => {
    // setPending(false);
    clearTimeout(timeout);
    clearInterval(interval);
  }, []);

  return [isPending, startTransCustom];
};

export default useTransCustom;
