const localStorageMock = function () {
  var store = {};
  return {
    getItem: function (key) {
      return store[key];
    },
    setItem: function (key, value) {
      store[key] = value.toString();
    },
    clear: function () {
      store = {};
    },
    removeItem: function (key) {
      delete store[key];
    },
  };
};

const funcStorage = (storage) => (
  {
    set(key, value) {
      storage.setItem(key, value);
      return storage.getItem(key);
    },
    get(key, defaultValue = undefined, silent = true) {
      const value = storage.getItem(key) || defaultValue;

      // if silent=false throw error
      if (!silent && !value) throw key + ' not found in storage';

      return value;
    },
    setObject(key, value) {
      storage.setItem(key, JSON.stringify(value));
      return storage.getItem(key);
    },
    getObject(key, defaultValue = {}, silent = true) {
      const value = this.get(key, JSON.stringify(defaultValue), silent);

      try {
        return JSON.parse(value);
      } catch (e) {
        if (!silent) throw 'Error in parsing value';
      }
      return {};
    },
    clear() {
      return storage.clear();
    },
    remove(key) {
      return storage.removeItem(key);
    },
  }
);

const localStorage = (typeof window?.localStorage !== "object") ? localStorageMock() : window.localStorage;
const sessionStorage = (typeof window?.sessionStorage !== "object") ? localStorageMock() : window.sessionStorage;

export const storageSession = () => funcStorage(sessionStorage);
export default funcStorage(localStorage); /* default storageLocal */
