import { useState, useEffect } from 'react';
import { Switch as Switcher } from '@headlessui/react';

export default function Switch({ handleSwitch, label = '', label2 = '', color = 'bg-primary', defaultValue = false }) {
  // console.log("DEFAULT VAL", defaultValue, typeof defaultValue);

  const [enabled, setEnabled] = useState(defaultValue);

  const onEnabled = () => {
    if (typeof handleSwitch === 'function') {
      handleSwitch(!enabled);
    }

    setEnabled(!enabled);
  };

  return (
    <>
      <div className="flex items-center space-x-3">
        <label htmlFor="switch-avail" className="text-sm leading-3 text-right">
          {label}<br />{label2}
        </label>
        <Switcher
          id="switch-avail"
          as="button"
          data-cy="available-switch"
          checked={enabled}
          onChange={() => onEnabled(!enabled)}
          className={`${enabled ? color : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-12 p-0.5 transition-colors duration-200 ease-in-out border-transparent rounded-full cursor-pointer focus:outline-none focus:shadow-outline`}
        >
          {({ checked }) => (
            <span className={`${checked ? 'translate-x-6' : 'translate-x-0'} inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full`} />
          )}
        </Switcher>
      </div>
    </>
  );
}
