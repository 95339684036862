import api from './service/api';
import { convertRgbToHex, applyColors, parseJSON } from './util';

import AlertDialog from './modal/AlertDialog';

type globalStore = {
  setUser: (user: any) => {},
  setSearch: (search: string) => {},
  setFilter: (filter: object) => {},
  setColors: (colors: object) => {},
};


async function getColors() {
  const response = await api.getColors();
  console.log('COLORS', response?.data);
  return response?.data;
}

async function getModules() {
  const response = await api.getMenuModules();
  console.log('MODULES', response?.data);
  return response?.data;
}

async function getOsoba() {
  const response = await api.getOsoba();
  console.log('OSOBA', response?.data);
  return response?.data;
}

const getUserData = async (context: globalStore) => {
  const rOsoba = await getOsoba();

  if (!rOsoba || rOsoba?.status !== 0) {
    AlertDialog({
      key: 'user-data-err',
      title: 'Komunikat',
      message: 'Błąd pobrania profilu użytkownika! ' + (rOsoba?.info || ""),
    });
    return false;
  }

  const dataOsoba = parseJSON(rOsoba?.dane);

  const userObj = {
    firstname: dataOsoba?.imie,
    lastname: dataOsoba?.nazwisko,
    company: dataOsoba?.nazwa_ktr,
    price_change: !!dataOsoba?.can_negotiate_price,
    stock_unlimit: !(!!dataOsoba?.has_stock_control),
    export_excel: !!dataOsoba?.export_excel,
    price_catalog: !!dataOsoba?.price_catalog,
    rabat_procent: !!dataOsoba?.rabat_procent,
    cart_dialog: !!dataOsoba?.cart_dialog,
    crosseling_mode: !!dataOsoba?.crosseling_mode,
    favorite_display: !!dataOsoba?.favorite_display,
    ribbon_display: !!dataOsoba?.ribbon_display,
    branch_price: !!dataOsoba?.branch_price,
    branch_show: !!dataOsoba?.branch_show,
    branch_odb: !!dataOsoba?.branch_odb,
    branch_new: !!dataOsoba?.branch_new,
    article_popup: !!dataOsoba?.article_popup,
    tariff_change: !!dataOsoba?.can_add_tariff,
    extra_info: dataOsoba?.extra_info || "",
    credit: { limit: 0, dept: -1, days: 0 },
    currency: dataOsoba?.currency || "PLN",
    is_han: dataOsoba?.is_han || "",
    modules: [0, 0],
    colors: { primary: "", secondary: "", highlight: ""},
    logo: "",
    // profile: "",
  };

  const resColors = await getColors();
  if (resColors?.status == 0) {
    const dataColors = parseJSON(resColors?.dane);
    if (Array.isArray(dataColors) && dataColors.length > 0) {
      userObj.colors.primary = convertRgbToHex(dataColors[0].cprimary);
      userObj.colors.secondary = convertRgbToHex(dataColors[0].csecondary);
      userObj.colors.highlight = convertRgbToHex(dataColors[0].chighlight);

      applyColors(userObj.colors);
    }
  }

  const resModules = await getModules();
  if (resModules?.status == 0) {
    const dataModules = parseJSON(resModules?.dane);
    dataModules.length > 0 && dataModules.forEach(el => {
      userObj.modules[+el.id-1] = 1;
    });
    // userObj.modules = parseFloat(dataModules.limit);
  }

  context.setUser(userObj);
  context.setFilter({});
  return true;
};

export default getUserData;
