import { useState, useRef, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { default as storage } from '../util/storage';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ButtonStyle = styled.button`
  > label > span {
    position:relative;
    z-index:1;
    left:2px;
  }

  > label span + span {
    position:absolute;
    top:8px;
    left:2px;
    width:100%;
    height:1px;
    background-color:white;
    z-index:0;
  }

  > label span + span {
    height:2px;
    top:7px;
  }
`;

const TSort = ({ handleChange = null, symbolType = 'symbol_art', sortByPrice = true }) => {
  const { t } = useTranslation("shop");
  const [isOpen, setIsOpen] = useState(false);
  const [isSort, setIsSort] = useState({ symbol: '', sort: '' });
  const refButton = useRef();

  const changeSort = (e, symbol, sort, caption = 'local-sort-mode') => {
    e.preventDefault();
    e.stopPropagation();

    if (refButton.current == undefined) return;

    setIsOpen(false);
    setIsSort({ symbol, sort });

    refButton.current.children[0].innerHTML = caption;
    typeof handleChange === 'function' && handleChange(symbol, sort);
    storage.set('local-sort-mode', JSON.stringify({ symbol, sort, caption }));
  };

  const blurList = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  useEffect(() => { 
    if (refButton.current == undefined) return;

    const sessionSort = storage.get('local-sort-mode');

    if (sessionSort) {
      const sortObj = JSON.parse(sessionSort);
      setIsSort({ symbol: sortObj.symbol, sort: sortObj.sort });
      let caption = t('sortowanie');

      if (sortObj.symbol === 'nazwa_art' && sortObj.sort === 'asc') {
        caption = t('nazwa') + ' ↑';
      }

      if (sortObj.symbol === 'nazwa_art' && sortObj.sort === 'desc') {
        caption = t('nazwa') + ' ↓';
      }

      refButton.current.children[0].innerHTML = caption;
    }
  }, []);

  return (
    <div className="relative inline-block text-left">
      <div>
        <ButtonStyle
          ref={refButton}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          onBlur={blurList}
          className="w-auto min-w-112 inline-flex justify-end px-3 py-2 text-sm font-normal leading-5 text-gray-700 transition duration-150 
            ease-in-out bg-white border border-gray-300 rounded shadow-sm pr-9 hover:text-gray-500 focus:outline-none focus:ring-1 
            focus:ring-primary focus:border-primary active:bg-gray-50 active:text-gray-800" 
          id="options-menu-sort"
          aria-haspopup="true"
          aria-expanded="true"
        >        
          <p>---------</p>
          <label className="absolute z-0 px-1 text-xs text-gray-500 -top-2 left-2">
            <span>{t('sortowanie')}</span>
            <span/>
          </label>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 z-1">
            <svg className="w-5 h-5 text-gray-400" viewBox="1 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
            </svg>
          </span>
        </ButtonStyle>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {(ref) => (
          <div
            ref={ref}
            className="absolute right-0 z-20 w-auto mt-2 origin-top-right rounded-md shadow-lg"
          >
            <div className="py-1 overflow-auto text-sm bg-white rounded-md shadow-xs ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu-sort">
                <button
                  type="button"
                  role="menuitem"
                  className={`${isSort.symbol == '' && isSort.sort == '' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                    text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                  onMouseDown={(e) => changeSort(e, "", "", "---------")}
                  disabled={isSort.symbol == '' && isSort.sort == ''}
                >
                  {t('brak sort.')}
                </button>
                <button
                  type="button"
                  role="menuitem"
                  className={`${isSort.symbol == 'nazwa_art' && isSort.sort == 'asc' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                    text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                  onMouseDown={(e) => changeSort(e, "nazwa_art", "asc", t('nazwa') + ' ↑')}
                  disabled={isSort.symbol == 'nazwa_art' && isSort.sort == 'asc'}
                >
                  {t('nazwa')}:&nbsp;↑
                </button>
                <button 
                  type="button"
                  role="menuitem"
                  className={`${isSort.symbol == 'nazwa_art' && isSort.sort == 'desc' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                    text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                  onMouseDown={(e) => changeSort(e, "nazwa_art", "desc", t('nazwa') + ' ↓')}
                  disabled={isSort.symbol == 'nazwa_art' && isSort.sort == 'desc'}
                >
                  {t('nazwa')}:&nbsp;↓
                </button>
                <button
                  type="button"
                  role="menuitem"
                  className={`${isSort.symbol == symbolType && isSort.sort == 'asc' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                    text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                  onMouseDown={(e) => changeSort(e, symbolType, "asc", t('symbol') + ' ↑')}
                  disabled={isSort.symbol == symbolType && isSort.sort == 'asc'}
                >
                  {t('symbol')}:&nbsp;↑
                </button>
                <button
                  type="button"
                  role="menuitem"
                  className={`${isSort.symbol == symbolType && isSort.sort == 'desc' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                    text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                  onMouseDown={(e) => changeSort(e, symbolType, "desc", t('symbol') + ' ↓')}
                  disabled={isSort.symbol == symbolType && isSort.sort == 'desc'}
                >
                  {t('symbol')}:&nbsp;↓
                </button>
                {sortByPrice && (
                  <>
                    <button
                      type="button"
                      role="menuitem"
                      className={`${isSort.symbol == 'cena_n' && isSort.sort == 'asc' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                        text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                      onMouseDown={(e) => changeSort(e, "cena_n", "asc", t('cena') + ' ↑')}
                      disabled={isSort.symbol == 'cena_n' && isSort.sort == 'asc'}
                    >
                      {t('cena')}:&nbsp;↑
                    </button>
                    <button
                      type="button"
                      role="menuitem"
                      className={`${isSort.symbol == 'cena_n' && isSort.sort == 'desc' ? 'bg-primary text-white cursor-not-allowed' : 'text-gray-700'} 
                        text-right w-full block px-4 py-2 text-sm leading-5 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100`}
                      onMouseDown={(e) => changeSort(e, "cena_n", "desc", t('cena') + ' ↓')}
                      disabled={isSort.symbol == 'cena_n' && isSort.sort == 'desc'}
                    >
                      {t('cena')}:&nbsp;↓
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default TSort;
