import { Router, Route, Switch, Redirect } from 'react-router';
import { useContext } from 'react';
import CatalogHeader from '../../catalog/components/CatalogHeader.tsx';
import Footer from '../footer';

import ScrollArrow from '../scrollarrow';

const RouteCatalog = (props) => (
  <>
    <CatalogHeader />
    <div className='container catalog flex flex-row flex-grow content-start items-stretch justify-start'>
      <Route {...props} />
    </div>
    <Footer />
    <ScrollArrow />
    <div id="show-article" />
    <div id="show-modal" />
  </>
);

export default RouteCatalog;
