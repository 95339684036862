import useTransCustom from '../hook/transition';
import styled from 'styled-components';

const ButtonPage = styled.button`
  &.btn-primary {
    background-color: var(--primary);
    color:white;
  }
  &.btn-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
`;

export default function Pager({ page, pageMax, maxItems, handlePage, transMax = 3000 }) {
  //const [items, setItems] = useState([]);
  const [isPending, startTrans] = useTransCustom(transMax);
  const maxItemsPage = parseInt(Math.ceil(maxItems / pageMax))
  const items = [];

  if (page > maxItemsPage) page = maxItemsPage;

  const startPage = (page + 3) > maxItemsPage && maxItemsPage > 3 ? maxItemsPage - 4 : Math.max(page - 2, 0)

  if (maxItemsPage > 1) {
    for (let i = startPage; i < maxItemsPage; i += 1) {
      if (i > startPage + 4) {
        break;
      }
      items.push(
        <ButtonPage
          type="button"
          key={`page_${i}`}
          onClick={() => !isPending && startTrans(() => handlePage(i))}
          className={`bg-white ${i === page ? 'btn-primary' : 'btn'} md:inline-flex ml-px relative inline-flex items-center px-4 py-2 
            border border-gray-300 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 
            focus:z-10 focus:outline-none focus:border-primary focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 
            transition ease-in-out duration-150`}
        >
          {i + 1}
        </ButtonPage>
      );
    }
  }

  return (
    <nav className="relative z-0 inline-flex shadow-sm">
      <button
        disabled={isPending}
        type="button"
        title="pierwsza strona"
        onClick={() => (!isPending && page > 0 ? startTrans(() => handlePage(parseInt(0, 10))) : false)}
        className={`${page == 0 ? 'hidden' : ''} relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 
          bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none 
          ring-primary focus:ring-2 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out 
          duration-150`}
        aria-label="First"
      >
        {/* Heroicon name: chevron-left */}
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="m15.75578,5.293a1,1 0 0 1 0,1.414l-3.293,3.293l3.293,3.293a1,1 0 0 1 -1.414,1.414l-4,-4a1,1 0 0 1 0,-1.414l4,-4a1,1 0 0 1 1.414,0z" />
          <path d="m9.65822,5.293a1,1 0 0 1 0,1.414l-3.293,3.293l3.293,3.293a1,1 0 0 1 -1.414,1.414l-4,-4a1,1 0 0 1 0,-1.414l4,-4a1,1 0 0 1 1.414,0z" />
        </svg>
      </button>
      <button
        disabled={isPending}
        type="button"
        title="poprzednia strona"
        onClick={() => (!isPending && page > 0 ? startTrans(() => handlePage(parseInt(page, 10) - 1)) : false)}
        className={`${page == 0 ? 'hidden' : ''} relative inline-flex items-center ml-1 px-2 py-2 rounded-md border border-gray-300 
          bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none 
          ring-primary focus:ring-2 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out 
          duration-150 md:rounded-r-none`}
        aria-label="Previous"
      >
        {/* Heroicon name: chevron-left */}
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
      </button>
      <div className="">
        {items}
      </div>
      <button
        disabled={isPending}
        type="button"
        title="następna strona"
        onClick={() => !isPending && page + 1 < maxItemsPage ? startTrans(() => handlePage(parseInt(page, 10) + 1)) : false}
        className={`${maxItemsPage <= page + 1 ? ' hidden' : ''} ml-px relative inline-flex items-center px-2 py-2 rounded-md border 
          border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none 
          ring-primary focus:ring-2 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out 
          duration-150 md:rounded-l-none`}
        aria-label="Next"
      >
        {/* Heroicon name: chevron-right */}
        <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>
      </button>
      <button
        disabled={isPending}
        type="button"
        title="ostatnia strona"
        onClick={() => !isPending && page + 1 < maxItemsPage ? startTrans(() => handlePage(parseInt(maxItemsPage-1, 10))) : false}
        className={`${maxItemsPage <= page + 1 ? ' hidden' : ''} ml-1 relative inline-flex items-center px-2 py-2 rounded-md border 
          border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none 
          ring-primary focus:ring-2 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out 
          duration-150`}
        aria-label="Last"
      >
        {/* Heroicon name: chevron-right */}
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" view-box="0 0 20 20" fill="currentColor">
          <path d="m10.12737,14.707a1,1 0 0 1 0,-1.414l3.293,-3.293l-3.293,-3.293a1,1 0 0 1 1.414,-1.414l4,4a1,1 0 0 1 0,1.414l-4,4a1,1 0 0 1 -1.414,0z" />
          <path d="m4.45863,14.707a1,1 0 0 1 0,-1.414l3.293,-3.293l-3.293,-3.293a1,1 0 0 1 1.414,-1.414l4,4a1,1 0 0 1 0,1.414l-4,4a1,1 0 0 1 -1.414,0z" />
        </svg>
      </button>
    </nav>
  );
}
