import { useState, useEffect } from 'react';

import ForwardDialog from '../../components/modal/forward';
import Portal from '../../components/context/portal';
import { parseJSON, compareDates } from '../../components/util/index';
import api from '../../components/service/api';

import SelectCalendarRange from '../../components/SelectCalendarRange';
import InputPrice from '../../components/InputPrice';
import InputText from '../../components/InputText';

const getBranchPrice = async (symbol) => {
  const resp = await api.getBranchPrice(symbol);
  return resp?.data;
};

const setBranchPrice = async (symbol, name, price, dateRange) => {
  const { dateFrom, dateTo } = dateRange;
  const resp = await api.setBranchPrice(symbol, name, price, dateFrom, dateTo);
  return resp?.data;
};

const BranchCalcProduct = ({ title = '', quantity = { symbol: '' }, handleClose = () => {}, handleUpdate = (id) => {} }) => {
  const [blockOK, setBlockOk] = useState(false);
  const [price, setPrice] = useState(0);
  const [text, setText] = useState('');
  const [dateRange, setDateRange] = useState(['', '']);

  useEffect(() => {
    getBranchPrice(quantity.symbol)
      .then(data => {
        if (data?.status != 0) return false;

        console.log('BranchPrice', data.dane);
      });
  }, []);

  const closeModal = () => {
    // const container = document.getElementById('show-modal');
    // unmountComponentAtNode(container);
    setTimeout(() => handleClose());
  };

  const goModal = () => {
    if (blockOK) return;

    handleUpdate(quantity.id);
    setBranchPrice(quantity.symbol, text, price, dateRange);

    closeModal();
  };

  const changeDate = (_dateRange) => {
    const _valid = compareDates(_dateRange[0], _dateRange[1]);

    setDateRange(_dateRange.map(date => date.toDateInput()));
    setBlockOk(!(_valid <= 0 || price <= 0 || text != ""));
  };

  const changePrice = (_price) => {
    const __price = parseFloat(String(_price).replaceAll(" ", "").replace(",", ".").replace(/[^\d.]/g, "").trim() || 0);

    setPrice(__price);
    setBlockOk(!(__price <= 0 || text != ""));
  };

  const changeText = (_text) => {
    const __text = String(_text).replaceAll('  ', ' ').replace(/[^\w]/g, '').trim();

    setText(__text);
    setBlockOk(!(price <= 0 || __text != ""));
  };

  return (
    <>
      <Portal elementId="show-modal">
        <ForwardDialog
          title={`Cennik oddziału`}
          open
          onClose={closeModal}
          onGoTo={goModal}
          textGoTo="Potwierdź cenę"
          blockOK={blockOK}
        >
          <>
            <div className="w-full mb-4">
              <div>
                Produkt: <b>{title}</b>
              </div>
            </div>
            <hr className="my-2 mb-6 border border-dashed border-t-1" />
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-x-2">
                <span className="flex-grow text-right">Nazwa:</span>
                <div className="relative flex items-center justify-center gap-2">
                  <InputText
                    className="w-64"
                    type="text"
                    defaultValue={""}
                    onChange={changeText}
                    // onFocus={(e) => e.currentTarget.select()}
                    tabIndex={1}
                  />
                </div>
              </div>
              <div className="flex items-center gap-x-2">
                <span className="flex-grow text-right">Cena:</span>
                <div className="relative flex items-center justify-center gap-2">
                  <InputPrice
                    className="w-64"
                    type="text"
                    defaultValue={0}
                    onChange={changePrice}
                    // onFocus={(e) => e.currentTarget.select()}
                    tabIndex={2}
                  />
                </div>
              </div>
              <div className="flex items-center gap-x-2">
                <span className="flex-grow text-right">Termin:</span>
                <div className="relative flex items-center justify-center gap-2">
                  <SelectCalendarRange
                    name="co_date"
                    defaultValue={["", ""]}
                    className={'w-64'}
                    onChange={changeDate}
                    tabIndex={3}
                  />
                </div>
              </div>
            </div>
          </>
        </ForwardDialog>
      </Portal>
    </>
  );
};

export default BranchCalcProduct;
