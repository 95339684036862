import { forwardRef } from 'react';
// import { history } from './index';
import { useHistory } from 'react-router-dom';

const Link = forwardRef(({ to, ...rest }, ref) => {
  const history = useHistory();
  const href = to; // history.createHref(to); // issue cycle dependency
  const { _, replace } = rest;

  const props = {
    ...rest,
    children: undefined,
    href,
    onClick: (event) => {
      // if (!target || target === '_self') return;

      const method = replace ? history.replace : history.push;

      event.preventDefault();
      method(to);
    },
  };

  return (
    <a {...props} ref={ref}>
      {rest.children}
    </a>
  );
});

export default Link;
