/**
 * Country List
 */

import dataCountry from './countries.json';

class CountryList {
  constructor() {
    this.data = dataCountry;
    this.labelMap = {};
    this.valueMap = {};

    this.data.forEach(country => {
      this.labelMap[country.text.toLowerCase()] = country.value;
      this.valueMap[country.value.toLowerCase()] = country.text;
    });
  }

  getData() {
    return this.data;
  }
}

export default CountryList;
