// context/global.js

import { createContext } from 'react';
import { default as storage } from '../util/storage';
import { createStore, createHook } from 'react-sweet-state';
import { applyColors } from '../util';
import "./i18n";

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const userObj = {
  firstname: '',
  lastname: '',
  company: '',
  price_change: false,
  stock_unlimit: false,
  export_excel: true,
  price_catalog: false,
  rabat_procent: false,
  cart_dialog: false,
  crosseling_mode: false,
  favorite_display: false,
  ribbon_display: false,
  branch_price: false,
  branch_show: false,
  branch_odb: false,
  branch_new: true,
  article_popup: false,
  tariff_change: false,
  credit: { limit: 0, debt: -1, days: 0 },
  currency: "PLN",
  extra_info: "",
  is_han: "",
  modules: [false, false],
  colors: { primary: "", secondary: "", highlight: "" },
  logo: "",
  profile: "",
};


const globalLang = storage.get('global-lang', 'pl');
const globalSearch = storage.getObject('global-search', '');
const globalSearchType = storage.getObject('global-search-type', 0);
const globalCatalogSearch = storage.getObject('global-catalog-search', '');
const globalCatalogSearchType = storage.getObject('global-catalog-search-type', 0);
const globalFilter = storage.getObject('global-filter', {});
const globalUser = storage.getObject('global-user', userObj);
const globalCategory = storage.getObject('global-category', '');

applyColors(globalUser?.colors);

const GlobalStore = createStore({
  initialState: {
    lang: globalLang,
    user: globalUser,
    category: globalCategory,
    search: globalSearch,
    searchType: globalSearchType,
    catalogSearch: globalCatalogSearch,
    catalogSearchType: globalCatalogSearchType,
    filter: globalFilter,
    mobile: false,
  },
  actions: {
    setLang: (lang) => ({ setState }) => {
      storage.set('global-lang', lang);
      setState({ lang });
    },
    setUser: (user) => ({ setState }) => {
      storage.setObject('global-user', user);
      setState({ user });
    },
    setCredit: (credit) => ({ setState, getState }) => {
      const user = getState().user;
      user.credit = credit;
      storage.setObject('global-user', user);
      setState({ user });
    },
    setSearch: (search) => ({ setState }) => {
      setState({ search: ""+ search });
    },
    setCatalogSearch: (search) => ({ setState }) => {
      setState({ catalogSearch: ""+ search });
    },
    setCategory: (category) => ({ setState }) => {
      setState({ category });
    },
    setSearchType: (searchType) => ({ setState }) => {
      setState({ searchType });
    },
    setCatalogSearchType: (catalogSearchType) => ({ setState }) => {
      setState({ catalogSearchType });
    },
    setFilter: (filter) => ({ setState, getState }) => {
      const objList = Object.keys(filter).length > 0 ? { ...getState().filter, ...filter } : Object.assign({});

      // remove undefined values
      Object.keys(objList).forEach(key => {
        if (objList[key] === undefined) delete objList[key];
      });

      setState({ filter: objList });

      // console.log("FilterSTORE", getState().filter, filter);
    },
    setMobile: (mobile) => ({ setState }) => {
      setState({ mobile });
    },
  },
});

export const useGlobal = createHook(GlobalStore);

export const GlobalContextProvider = ({ children }) => {
  const [current, send] = useGlobal();
  return (
    <GlobalStateContext.Provider value={current}>
      <GlobalDispatchContext.Provider value={send}>
        <>
          {children}
          <div id="portal-root" />
        </>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};