import { useHistory } from 'react-router-dom';

const Link = ({ to = '/', className = '', children }) => {
  const history = useHistory();

  const onGoTo = (e) => {
    e.preventDefault();
    history.push(to);

    return false;
  };

  return (
    <a
      className={`hover:cursor-pointer ${className}`}
      href={void(0)}
      onClick={onGoTo}
    >
      {children}
    </a>
  );
};

export default Link;
