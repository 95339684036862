enum NaviCommand {
  pageNext = 'pageNext',
  pagePrev = 'pagePrev'
};

const useSNavigate = ({ handleChange = (command: string) => {} }): object => {
  const handleNavigate = (e) => {
    //e.preventDefault();

    if (!e.ctrlKey && e.key === 'ArrowRight') {
      if (e.target.tagName == 'INPUT' || e.target.tagName == 'BUTTON' || e.target.tagName == 'TEXTAREA') return;
      const el = e.target.closest('td')?.nextElementSibling;
      if (el != null) el.focus();
      console.log("ArrowRight", el);
    }

    
    if (!e.ctrlKey && e.key === 'ArrowLeft') {
      if (e.target.tagName == 'INPUT' || e.target.tagName == 'BUTTON' || e.target.tagName == 'TEXTAREA') return;
      const el = e.target.closest('td')?.previousElementSibling;
      if (el != null) el.focus();
      console.log("ArrowLeft", el);
    }
    
    // navigate page +1
    if (e.ctrlKey && e.key === 'ArrowRight') {
      handleChange(NaviCommand.pageNext);
    }
    // navigate page -1
    if (e.ctrlKey && e.key === 'ArrowLeft') {
      handleChange(NaviCommand.pagePrev);
    }

    if (e.key === 'ArrowUp') {
      // if (e.target.tagName == 'INPUT') return;
      let el = e.target.closest('tbody')?.previousElementSibling;
      if (el != null) el.querySelector(`td[tabIndex="${e.target.closest('td').tabIndex}"]`).focus();
    }

    if (e.key === 'ArrowDown') {
      // if (e.target.tagName == 'INPUT') return;
      let el = e.target.closest('tbody')?.nextElementSibling;
      if (el != null) el.querySelector(`td[tabIndex="${e.target.closest('td').tabIndex}"]`).focus();
    }

    // Tag: input, select
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'SELECT' || e.target.tagName == 'TEXTAREA') {
      if (e.key === 'Escape') {
        e.target.closest('td').focus();
      }

      if (e.key === 'Enter' && e.target.type === 'tel') {
        console.log("ENTER & TEL");
        e.target.closest('td').focus();
        const { tabIndex } = e.target.closest('td');

        let el = e.target.closest('tbody')?.nextElementSibling;
        let breakLoop = false;

        do {
          if (el == null) {
            e.target.closest('td').blur();
            break;
          }

          const inputTel = el.querySelector(`td[tabIndex="${tabIndex}"] input[type="tel"]`);
          if (inputTel != null && inputTel?.disabled != true) {
            el.querySelector(`td[tabIndex="${tabIndex}"] input[type="tel"]`).focus();
            // el.querySelector(`td[tabIndex="${tabIndex}"] input[type="tel"]`).select();
            break;
          }
          
          el = el.nextElementSibling;
        } while(true);
      }

      // if (e.key === 'Enter' && e.target.type === 'checkbox') {
      //   e.target.checked = !e.target.checked;
      // }

      return;
    }

    if (e.key === 'Enter') {
      // e.preventDefault();
      console.log("ENTER");
      const el = e.target.querySelector("input[type='tel']");
      if (el != null) {
        el.focus();
        // setTimeout(() => el.select(), 1000);
        // el.select();
        return;
      }

      const el4 = e.target.querySelector("select");
      if (el4 != null) {
        el4.focus();
        return;
      }

      const el3 = e.target.querySelector('button');
      if (el3 != null) {
        el3.click();
        return;
      }

      const el2 = e.target.querySelector("input[type='checkbox']");
      if (el2 != null) {
        el2.checked = el2.checked !== true;
        return;
      }
    }
  };

  return { handleNavigate };
};

export default useSNavigate;
