import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const PageInfo = ({ maxItems, pageMax, page = 0, pageAll = 0, handlePage = function(page) {} }) => {
  const { t } = useTranslation("shop");
  const refInput = useRef();
  const maxItemsPage = parseInt(Math.ceil(maxItems / pageMax));

  const onChangePage = (e) => {
    let pageChange = 0;

    if (+e.target.value - 1 >= maxItemsPage)
      pageChange =  maxItemsPage - 1;
    else
    if (+e.target.value - 1 < 0)
      pageChange = 0;
    else
      pageChange = +e.target.value - 1;

    if (pageChange == page)
      return false;

    if (typeof handlePage === 'function') {
      handlePage(parseInt(pageChange, 10));
    }
  };

  const onEnterPage = (e) => {
    if (e.key !== 'Enter') return false;

    e.target.blur();
  };

  const onValidateInput = (e) => {
    if (!(e.keyCode === 8 || (e.charCode >= 48 && e.charCode <= 57)))
      e.preventDefault();
  }

  useEffect(() => {
    if (refInput.current)
      refInput.current.value = page + 1;
  }, [page, pageAll]);

  return (
    <p className="text-sm leading-5 text-gray-700">
      <span className="hidden pr-2 md:inline-block" lang="pl">{t('Razem')}</span>
      <span className="hidden font-semibold sm:inline-block">
        {maxItems}{pageAll > 0 && (<>&nbsp;(-{pageAll})</>)}
      </span>
      <span className="hidden px-2 text-gray-300 sm:inline-block">|</span>
      <span className="hidden pr-2 lg:inline-block">{t('Strona')}</span>
      <input 
        className="hidden xxs:inline-block w-10 py-2 px-1 text-center focus:ring-primary focus:border-primary h-full border border-gray-300 text-gray-500 sm:text-sm rounded-md  appearance-none"
        type="number" 
        min="1"
        step="1"
        ref={refInput}
        defaultValue={page + 1}
        onBlur={onChangePage}
        onKeyUp={onEnterPage}
        onKeyPress={onValidateInput}
      />
      <span className="hidden w-6 text-center xs:inline-block">
        {t('z')}
      </span>
      <span className="hidden font-semibold xs:inline-block">
        {maxItemsPage}
      </span>
    </p>
  )
};

export default PageInfo;
