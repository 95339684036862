const Battery = ({ value, min = 0, max = 4 }) => {
  let battValue = 100;
  let battColor = 'bg-primary';
  let batteryText = 'white';

  const counter = (max - min) / 4;

  if (value <= min) {
    battValue = 10;
    battColor = 'bg-red-400';
  } else
  if (value <= counter + min) {
    battValue = 25;
    battColor = 'bg-yellow-500';
    batteryText = 'gray';
  } else
  if (value <= (counter * 2) + min) {
    battValue = 50;
    battColor = 'bg-yellow-400';
    batteryText = 'gray';
  } else
  if (value <= (counter * 3) + min) {
    battValue = 75;
    battColor = 'bg-green-400';
  }

  return (
    <div className="flex justify-end">
      <div className="relative w-12 flex bg-white border border-gray-300 rounded-sm">
        <div className="absolute z-1 flex h-2 mt-2 ml-12 border-r-4 border-gray-400 rounded-r -left-px" />
        <div
          className={`h-4 m-1 py-1 cursor-default ${battColor} text-xs font-bold leading-none flex items-center justify-center text-center text-white `}
          style={{ width: `${battValue}%` }}
        >
          {battValue > 10 && (
            <div className={`absolute left-1/2 transform -translate-x-1/2 text-${batteryText}-700`}>{battValue}%</div>
          )}
          {battValue === 10 && (
            <div className="absolute left-0 ml-5 text-red-400 animate-blink">
              <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Battery;
