import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import parser from 'bbcode-to-react';

import api from '../service/api.ts';
import { parseJSON, numberFormatCurrency, numberFormatQuantity } from '../util';
import Portal from '../context/portal';

import ExitIcon from './exiticon';
import IconButton from './iconbutton';

import ArticleTile from '../../pages/article/tile';
import LazyImage from '../hook/lazyimage';
import useFileCache from '../hook/useFileCache';
import Link from '../link';
import { useTranslation } from 'react-i18next';

const DivModal = styled.div`
  z-index:1850; /* prevent jqwidgets overlay*/
  
  & section.cart-dialog .product-list .item {
    width: 233px;
  }

  & div.scroll {
    scrollbar-width: thin;
    scrollbar-color: var(--primary) #f8f8f8;
    overflow-x: auto;
  }
`;

const ButtonArrow = styled.button`
  background-color: rgba(255,255,255,1);
  box-shadow: 0 0 8px rgba(0,0,0,.25);
  color:#8d9299;

  &:hover {
    background-color: var(--primary);
  }
`;

const getArticleCrossSell = async (symbol) => {
  const resp = await api.getArticleCrossSelling(symbol);
  return resp?.data;
};

const CartDialog = ({ article, title, dialogKey, onClose = () => {}, bgColor = undefined, iconColor = undefined }) => {
  const { t } = useTranslation("shop");
  const [articleCrossList, setArticleCrossList] = useState([]);
  const [loadArticleImage] = useFileCache();
  const refScroll = useRef();
  const propBgColor = bgColor || "bg-white";
  const propIconColor = iconColor || "text-gray";
  let isMounted = true;

  const offClose = (e) => {
    e.stopPropagation();
  };

  const onScroll = (e) => {
    if (refScroll.current == undefined) return;

    const scrollInc = e?.special ? 245 : 80;
    if (e.deltaY > 0)
      refScroll.current.scrollLeft += scrollInc;
    else
      refScroll.current.scrollLeft -= scrollInc;

    e.preventDefault();
    // e.stopPropagation();
  };

  useEffect(() => {
    isMounted = true;

    getArticleCrossSell(article.symbol)
      .then((data) => {
        if (data?.status !== 0) return;

        const list = parseJSON(data?.dane);
        if (!Array.isArray(list)) return;

        // remove duplicates
        if (isMounted) {
          setArticleCrossList(list.filter((art, i) => i === list.findIndex((dup) => dup?.symbol_art === art?.symbol_art)));
        }
        // setArticleCrossList(list);
      });

    return () => {
      isMounted = false;
    }
  }, []);

  return (
    <Portal elementId="show-modal">
      <DivModal
        className="fixed inset-0 z-50 flex overflow-auto transition bg-gray-800 bg-opacity-50 modal-bg"
        data-key={dialogKey}
        onClick={onClose}
      >
        <div className={`relative w-full max-w-3xl max-h-screen m-auto flex-col flex rounded-lg shadow transition ${propBgColor}`}>
          <div
            className="p-4"
            onClick={offClose}
          >
            <h2 className="text-xl">{title}</h2>
            <div className="pt-2">
              <div className="flex flex-col md:flex-row items-start gap-6 md:gap-2 py-5">
                <div className="flex flex-row flex-grow gap-4 justify-center md:justify-start w-full">
                  <picture className="flex justify-center flex-shrink-0 border border-gray-200 rounded-sm w-15 h-15">
                    {/* <source media="(max-width:650px)" srcSet={imgNotFound} /> */}
                    <Link to={`/article/show/${encodeURIComponent(article.symbol)}`}>
                      <LazyImage
                        className="h-15 w-15"
                        src=""
                        symbol={article.symbol}
                        width="120"
                        height="120"
                        alt=""
                        handlerLoad={(symbol) => loadArticleImage(symbol, 120)}
                      />
                    </Link>
                  </picture>
                  <div className="flex flex-col">
                    <h2 className="mb-2">
                      {parser.toReact(article.title)}
                      <div className="text-sm">
                        <span className="text-gray-500">{article.label_name}: </span>
                        {article.symbol}
                      </div>
                    </h2>
                    <div className="flex flex-row gap-2">
                      <span className="mt-1 text-gray-500">{t('Cena netto')}: </span>
                      <span className="block text-xl text-red-400 md:whitespace-nowrap">
                        {numberFormatCurrency(+article.price, article.currency)}
                      </span>
                    </div>
                    <div className="flex flex-row gap-2">
                      <span className="text-gray-500">{t('Ilość')}: </span>
                      <span>{numberFormatQuantity(article.confirm, article.precision)}&nbsp;{article.jm}</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row md:flex-col self-stretch justify-center flex-shrink-0 gap-2">
                  <Link
                    className="px-4 py-2 text-sm text-center text-white rounded bg-primary hover:bg-gray-700"
                    to={`/cart`}
                  >
                    {t('Przejdź do\u00A0koszyka')}
                  </Link>
                  <button
                    type="button"
                    className="px-4 py-2 text-sm text-center text-gray-800 bg-gray-200 rounded hover:bg-gray-700 hover:text-white"
                    onClick={onClose}
                    // autoFocus={true}
                    ref={input => input && input.focus()}
                  >
                    {t('Kontynuuj zakupy')}
                  </button>
                </div>
              </div>
              {articleCrossList.length > 0 && (
                <>
                  <p className="pt-5 mt-1 mb-3 text-lg text-center border-t border-gray-300 border-dashed">{t('Podobne produkty')}</p>
                  <div className="relative flex flex-col">
                    {articleCrossList.length > 3 && (
                      <ButtonArrow
                        type="button"
                        className={`absolute hidden md:flex items-center justify-center w-20 h-20 top-1/2 -mt-16 rounded-full bg-gray-600 -left-28 z-10`}
                        onClick={() => onScroll({ preventDefault: () => {}, deltaY: -1, special: 1 })}
                      >
                        {/* ICON arrow left*/}
                        <svg className='w-8 h-8' viewBox="0 0 1024 1024" fill="currentColor">
                          <path d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z" />
                        </svg>
                      </ButtonArrow>
                    )}
                    <div
                      className="scroll"
                      ref={refScroll}
                      onWheelCapture={onScroll}
                    >
                      <section className="pb-2 w-max cart-dialog">
                        <ArticleTile
                          data={articleCrossList}
                          handleSort={(key, direction) => false}
                          handleUpdate={() => false}
                          headerCartSelect={false}
                          // noDetail={true}
                          noHeader
                          noVariant
                          noDialog
                        />
                      </section>
                    </div>
                    {articleCrossList.length > 3 && (
                      <ButtonArrow
                        type="button"
                        className={`absolute hidden md:flex items-center justify-center w-20 h-20 -mt-16 rounded-full bg-gray-600 top-1/2 -right-28`}
                        onClick={() => onScroll({ preventDefault: () => {}, deltaY: 1, special: 1 })}
                      >
                        {/* ICON arrow right */}
                        <svg className="w-8 h-8" viewBox="0 0 1024 1024" fill="currentColor">
                          <path d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z" />
                        </svg>
                      </ButtonArrow>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="absolute p-4 -top-1 -right-1">
            <IconButton onClick={() => onClose()}>
              <ExitIcon color={propIconColor} />
            </IconButton>
          </div>
        </div>
      </DivModal>
    </Portal>
  );
};

export default CartDialog;
