
import Pattern from './pattern';
import MenuSide from '../../components/side/menuside';

const PatternCart = () => {
  return (
    <>
      <MenuSide onlyMobile={true} />
      <main className="w-full px-4 m-2 py-4">
        <Pattern />
      </main>
    </>
  );
};

export default PatternCart;
