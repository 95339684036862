import { Suspense, lazy } from 'react';
import { AuthProvider, AuthContext } from '@ryanar/react-auth-provider';
// import { Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router';

import { ThreeDots as Loader } from 'react-loader-spinner';
import CatalogArticles from '../../catalog/CatalogArticles';
import CatalogArticleShow from '../../catalog/CatalogArticleShow';

import Page404 from '../../pages/404';
const UserLogin = lazy(() => import('../../pages/user/login'));
const UserRecovery = lazy(() => import('../../pages/user/recovery'));
const UserPassword = lazy(() => import('../../pages/user/password'));
const UserSettings = lazy(() => import('../../pages/user/settings'));
import Article from '../../pages/article';
import ArticleShow  from '../../pages/article/show2';
import PatternCart from '../../pages/pattern';
import Cart  from '../../pages/cart';
const Client = lazy(() => import('../../pages/client'));
const Intro = lazy(() => import('../../pages/home/index'));

import RouteMain from './RouteMain';
import RouteCatalog from './RouteCatalog';
import RouteBlank from './RouteBlank';

const history = createHistory();

const RouterContextProvider = (props) => {
  // const history = useHistory();

  let authenticated = false;

  const handleLogin = () => {
    authenticated = true;
  };

  const handleLogout = () => {
    authenticated = false;
  };

  // const handleRoute = e => {
  //   console.log("ROUTE", e);
  //   if (e.url === '/') history.push('/article');

  //   // if (e.url === '/login') return true;

  //   // if (!authenticated)
  //   //   route('/login', true);
  // };

  const fallBack = () => (
    <div className="absolute transform -translate-x-1/2 -translate-y-1/2 w-200 h-100 top-1/2 left-1/2">
      <Loader type="ThreeDots" color="var(--primary)" height="100" width="100" />
    </div>
  );

  return (
    <AuthProvider onLogin={handleLogin} onLogout={handleLogout}>
      <Router history={history}>
        <Suspense fallback={fallBack()}>
          <Switch>
            <RouteCatalog path="/catalog/show/:symbol">
              <CatalogArticleShow />
            </RouteCatalog>
            <RouteCatalog path="/catalog">
              <CatalogArticles />
            </RouteCatalog>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/login">
              <UserLogin AuthContext={AuthContext} />
            </Route>
            <Route path="/recovery">
              <UserRecovery />
            </Route>
            <Route path="/password/:hash">
              <UserPassword />
            </Route>
            <RouteMain path="/settings">
              <UserSettings />
            </RouteMain>
            <RouteMain path="/home">
              <Intro />
            </RouteMain>
            <RouteMain path="/article/show/:symbol">
              <ArticleShow />
            </RouteMain>
            <RouteMain path="/article" component={Article} />
            <RouteMain path="/pattern">
              <PatternCart />
            </RouteMain>
            <RouteMain path="/cart">
              <Cart />
            </RouteMain>
            <RouteMain path="/client">
              <Client />
            </RouteMain>
            <Route>
              <Page404 default />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export {
  RouterContextProvider,
  AuthContext,
  history,
};
