import { useState, useEffect } from 'react';
import {
  trackPromise,
  manuallyIncrementPromiseCounter,
  manuallyResetPromiseCounter, 
} from 'react-promise-tracker';

import useFileCache from '../hook/useFileCache';
import { SpinnerTracker } from "../spinner";
import api from '../service/api';
import { parseJSON } from '../util/index';

import './style.css';


const Slider = () => {
  const [slide, setSlide] = useState(1);
  const [maxSlide, setMaxSlide] = useState(0);
  const [linkSlide, setLinkSlide] = useState([]);
  const [sliderList, setSliderList] = useState([]);
  const [,, loadSliderImage] = useFileCache();
  const [intervalSlide, setIntervalSlide] = useState(5);

  const getSliderCount = async function() {
    const resp = await trackPromise(api.getSliderCount(), 'slider-list');
    return resp?.data;
  };

  const changeSlider = (num) => {
    if (num === 0) num = (+slide + 1);
    if (Math.abs(num) > maxSlide || Math.abs(num) <= 0) num = 1;

    setSlide(num);
  };

  useEffect(() => {
    const intervalImg = setInterval(() => {
      clearInterval(intervalImg);
      changeSlider(0);
    }, (intervalSlide * 1000));

    if (slide < 0) clearInterval(intervalImg);
    return () => clearInterval(intervalImg);
  }, [slide, sliderList]);

  useEffect(() => {
    getSliderCount().then(async (data) => {
      if (!data || data?.count <= 0 || data?.status != 0) return;

      const promiseList = [];
      const dataSlide = parseJSON(data.dane);

      setMaxSlide(parseInt(data.count, 10));
      setLinkSlide(dataSlide?.link || []);
      setIntervalSlide(dataSlide?.interval || 5);

      for (let i = 0; i < data.count; i += 1) {
        promiseList.push(loadSliderImage(i, 1920));
      }

      manuallyIncrementPromiseCounter('slider-list');
      Promise.allSettled(promiseList).then((list) => {
        // console.log("SLIDER promise", list);

        const listTmp = list
          .filter((slider) => slider.status == 'fulfilled' && slider.value != '')
          .map((slider) => slider.value);

        setSliderList(listTmp);
      }).finally(() => {
        manuallyResetPromiseCounter('slider-list');
      });
    });
  }, []);

  return (
    <>
      <div className="relative h-auto mySlider">
        <SpinnerTracker area="slider-list" />
        {/*
        <div className={`top-0 mx-auto transition-all duration-500 ease-in-out w-full ${slide === 1 ? 'relative z-10' : 'opacity-0 absolute'}`}>
          <div className="object-fill w-full h-auto px-10 py-24 text-white bg-center bg-cover min-h-360" style={{ backgroundImage: 'url("https://demo.izamowienia.pl/photos/slider/hermes.jpg")' }}>
            <div className="relative z-10 md:w-1/2">
              <p className="text-sm font-bold uppercase">Humansoft</p>
              <p className="text-3xl font-bold">Zamawiarka B2B</p>
              <p className="mb-10 text-2xl leading-none">Poznaj wysokiej klasy system ERP!</p>
              <a href={void(0)} className="px-8 py-4 text-xs font-bold text-white uppercase rounded bg-primary hover:bg-gray-200 hover:text-gray-800">Kontakt do nas</a>
            </div>
            <div className="overlay" />
          </div>
        </div>
        */}
        {sliderList.length == 0 && (
          <div
            key={`slider_img_init`}
            className={`top-0 mx-auto transition-all duration-500 ease-in-out w-full relative z-10`}
          >
            <div
              className="object-fill w-full h-auto text-white bg-gray-100 bg-center bg-cover"
              // style={{ backgroundImage: `url("${urlBlob}")` }}
            >
              <a
                className="block w-full h-full"
                href={void(0)}
                target="_blank"
                rel="noreferrer"
                // download="slider_init.jpg"
              >
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  className="w-full"
                  style={{height: '800px'}}
                  alt="slider_init"
                />
              </a>
              <div className="relative z-10 md:w-1/2">
                {/* <p className="mb-10 text-2xl leading-none">Poznaj wysokiej klasy system ERP!</p> */}
              </div>
            </div>
          </div>
        )}
        {sliderList.length > 0 && sliderList.map((urlBlob, index) => (
          <div
            key={`slider_img_${index}`}
            className={`top-0 mx-auto transition-all duration-500 ease-in-out w-full ${index + 1 === Math.abs(slide) ? 'relative z-10' : 'opacity-0 absolute'}`}
            onMouseEnter={() => changeSlider(-(index + 1))}
            onMouseLeave={() => changeSlider(index + 1)}
          >
            <div
              className="object-fill w-full h-auto text-white bg-gray-100 bg-center bg-cover"
              // style={{ backgroundImage: `url("${urlBlob}")` }}
            >
              <a 
                className={`block w-full h-full ${(""+linkSlide[index]) != "" ? 'hover:cursor-pointer' : ''}`}
                href={linkSlide[index] || void(0)}
                target="_blank"
              >
                <img src={`${urlBlob}`} className="w-full" alt={`slider_${index}`} download={`slider_${index}.jpg`} />
              </a>
              <div className="relative z-10 md:w-1/2">
                {/* <p className="mb-10 text-2xl leading-none">Poznaj wysokiej klasy system ERP!</p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center w-full pb-2 mx-auto mt-4">
        {sliderList.length > 0 && sliderList.map((_, index) => (
          <button
            type="button"
            key={`slider_btn_${index}`}
            label={`${index + 1}`}
            onClick={() => changeSlider(index + 1)}
            className={`rounded-full w-4 h-4 pb-2 mx-2 ${Math.abs(slide) === (index + 1) ? 'bg-primary' : 'bg-primary opacity-30'}`}
          />
        ))}
      </div>
    </>
  );
};

export default Slider;
