import { PropTypes } from 'prop-types';
//import { numberFormat } from '../util/index'

const defaultClassName = '';

const TRow = ({ name, symbol, data, tabindex, ref = null, template = '', defaultValue = '', catalog=false }) => {
  let value = data[symbol] ?? defaultValue;

  return (
    <>
      {typeof template === 'function' ? template(data, ref) : value}
    </>
  );
};

TRow.propTypes = {
  name: PropTypes.string,
};

TRow.defaultProps = {
  name: '',
};

export default TRow;
